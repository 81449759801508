import React from 'react';
import '../styles/Home.css';
import cegep from '../../public/cegep.jpg';
import uni from '../../public/udes.png';
import Credentials from './Credentials';
import { Link } from 'react-router-dom';

function Home(props) {
  return (
    <div>
      <section className="section section_over">
        <div className="columns">
          <div className="column colLeft">
            <div className="columns">
              <div className="column">
                <figure className="image is-3by1">
                  <img src={uni} alt="Logo de l'UdeS" />
                </figure>
              </div>
              <div className="column innerCol">
                <figure className="image is-3by1">
                  <img src={cegep} alt="Logo du cegep de Sherbrooke" />
                </figure>
              </div>
            </div>
          </div>
          <div className="column colRight">
            <Link to="/login">
                <button className="button is-primary is-inverted" style={{marginRight: "30px"}}>CONNEXION</button>
            </Link>
            <Link to="/signup">
                <button className="button is-info is-outlined is-rounded" style={{marginRight: "105px"}}>INSCRIPTION</button>
            </Link>
          </div>
        </div>
      </section>
      <section className="sectionTwo">
        <div className="credentials">
          <Credentials isLogin={props.isLogin} />
        </div>
      </section>
    </div>
  );
}

export default Home;