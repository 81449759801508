import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import requests from './clientRequests';
import './styles/Surveys.css';
import enums from './enums';
import MainMenu from './components/MainMenu';

const { getSurveys } = requests;
const { surveyStatus, statusCodes } = enums;

function Surveys(props) {
    const [surveys, setSurveys] = useState([]);
    
    const history = useHistory();
    const reportSuffix = " (rapport)";

    useEffect(() => { document.body.style.backgroundColor = '#f8fbfa' }, []);
    useEffect(() => {
        getSurveys().then(res => {
            if (res.statusCode === statusCodes.client.noUserToken) {
                return history.push("/login");
            }
            else if (res.statusCode === statusCodes.api.success) {
                setSurveys(res.surveys);
            } else {
                // handle other errors here?
            }
        });
    }, []);
    
    const getSurveysHtml = () => {
        let statusText;
        let textColor;

        return surveys.map((element) => {
            let limit = "Indisponible";

            if (element.status === surveyStatus.finished) {
                statusText = "Complété";
                textColor = "has-text-success";
            }
            else if (element.status === surveyStatus.inProgress) {
                statusText = "À terminer";
                textColor = "has-text-warning-dark";
            }
            else {
                statusText = "À compléter";
                textColor = "has-text-danger";
            }

            if (element.survey_deadline) {
                const strArr = element.survey_deadline.split("-");
                const date = new Date(Date.UTC(strArr[0], strArr[1], strArr[2].slice(0, 2))).toLocaleDateString();
                limit = date;
            }
            return (
                <li key={element.survey_id}>
                    <div className="content surveyElement">
                        {
                            element.status === surveyStatus.finished ?  
                            <p className="surveyTitle has-text-info">{element.name}</p>
                            :
                            (<Link to={ "/questionnaire/" + element.survey_id }>
                                <p className="surveyTitle has-text-info">{element.name}</p>
                            </Link>)
                        }
                        <div className="surveyDetails">
                            <p style={{marginBottom: 0}} className={textColor}>{statusText}</p>
                            <p className="has-text-grey">Date limite : {limit}</p>
                        </div>
                    </div>
                </li>
            );
        });
    }

    const getReportableSurveys = () => {
        return surveys.filter(survey => {
            return survey.status === surveyStatus.finished;
        });
    }

    const getReportsHtml = () => {
        const eligibleSurveys = getReportableSurveys();

        return eligibleSurveys.map(survey => {
            return (
                <li key={"report_" + survey.survey_id}>
                    <div className="content surveyElement">
                        <Link to={ "/report/" + survey.survey_id }>
                            <p className="surveyTitle has-text-primary">{survey.name + reportSuffix}</p>
                        </Link>
                    </div>
                </li>
            )
        });
    }

    return (
        <div>
            <MainMenu />
            <div className="section mainContent">
                <div className="container is-max-desktop">
                    <div className="card surveyCard">
                        <div className="card-content">
                            <div className="content">
                                <h2 className="surveysTitle has-text-info-dark">Questionnaires</h2>
                                <p className="surveySubTitle has-text-grey">Voici les questionnaires disponibles</p>
                            </div>
                            <ul style={{margin: "40px 0 30px 0"}}>
                                { getSurveysHtml() }
                            </ul>
                        </div>
                    </div>

                    {getReportableSurveys().length ? <div className="card reportCard">
                        <div className="card-content">
                            <div className="content">
                                <h2 className="surveysTitle has-text-primary-dark">Rapports</h2>
                                <p className="surveySubTitle has-text-grey">Voici les rapports disponibles</p>
                            </div>
                            <ul style={{margin: "40px 0 30px 0"}}>
                                { getReportsHtml() }
                            </ul>
                        </div>
                    </div> : null}
                </div>
            </div>
        </div>
    )
}

export default Surveys;
