import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import requests from './clientRequests';
import MainMenu from './components/MainMenu';
import './styles/Report.css'
import enums from './enums';
import credibilite from '../public/credibilite.png';
import assiete from '../public/assiete.png';
import fruitsLegumesTableau from '../public/fruitsLegumesTableau.png';
import proteines from '../public/proteines.png';
import proteinesVege from '../public/proteinesVege.png';
import fruitsLegumes from '../public/fruitsLegumes.png';
import exportation from '../public/exportation.png';
import legumineuses from '../public/legumineuses.png';
import poissons from '../public/poissons.png';
import oeufs from '../public/oeufs.png';
import grains from '../public/grains.png';
import cereale from '../public/cereale.png';
import classificationNova from '../public/classificationNova.png';
import pichet from '../public/pichet.png';
import varierEau from '../public/varierEau.png';
import bouteilleEau from '../public/bouteilleEau.png';
import boissonCafe from '../public/boissonCafe.png';
import boissonSoya from '../public/boissonSoya.png';
import boissonSport from '../public/boissonSport.png';
import bulles from '../public/bulles.png';
import section2 from '../public/section2.png';
import typesMatin from '../public/typesMatin.png';
import echelleFaim from '../public/echelleFaim.png';
import TableauAlimentsProteines from '../public/Tableau-Aliments-proteines.png';
import TableauAlimentsProteinesSi8b from '../public/Tableau-aliments-proteines-si8b.png';
import TableauDesLegumesFruits from '../public/Tableau-des-legumes-fruits.png';
import EtiquetageNutritionnel101 from '../public/Etiquetage-nutritionnel-101.png';

const { getAnswerSelections } = requests;
const { statusCodes } = enums;

function Report(props) {
    const { surveyId } = useParams();

    const [selections, setSelections] = useState([]);

    useEffect(() => { document.body.style.backgroundColor = '#f8fbfa' }, []);
    useEffect(() => {
        return getAnswerSelections(surveyId).then(res => {
            if (res.statusCode === statusCodes.client.noUserToken) {
                return history.replace("/login");
            }
            if (res.statusCode === statusCodes.api.success) {
                const questionSelectionsMap = res.selections.reduce((acc, value) => {
                    if (acc[value.question_id] === undefined) {
                        return { ...acc, [value.question_id]: { ...value } };
                    }
                    if (value.sub_question_id) {
                        // question has sub questions
                        if (acc[value.question_id].subQuestions) {
                            return { ...acc, [value.question_id]: { 
                                subQuestions: {
                                    ...acc[value.question_id].subQuestions,
                                    [value.sub_question_id]: { ...value },
                                }
                            }};
                        }
                        return { ...acc, [value.question_id]: {
                            subQuestions: {
                                [acc[value.question_id].sub_question_id]: { ...acc[value.question_id] },
                                [value.sub_question_id]: { ...value },
                            }
                        }}
                    }
                    else {
                        // question has multiple checkboxes
                        if (acc[value.question_id].fields) {
                            return { ...acc, [value.question_id]: {
                                fields: [ ...acc[value.question_id].fields, { ...value }]
                            }};
                        }
                        return { ...acc, [value.question_id]: {
                            fields: [{ ...acc[value.question_id] }, { ...value }],
                        }};
                    }
                }, {});
                
                Object.keys(questionSelectionsMap).forEach(questionId => {
                    if (questionSelectionsMap[questionId].subQuestions) {
                        let newSubObj = {};
                        const keys = Object.keys(questionSelectionsMap[questionId].subQuestions);
                        const smallestId = keys.reduce((acc, subId) => {
                            if (subId < acc) {
                                return subId;
                            }
                            return acc;
                        }, keys[0]);

                        keys.forEach(subId => {
                            newSubObj[subId - smallestId + 1] = { ...questionSelectionsMap[questionId].subQuestions[subId] }
                        });

                        questionSelectionsMap[questionId].subQuestions = { ...newSubObj };  // deep copy
                    }
                    if(questionSelectionsMap[questionId].fields === undefined ){
                        // Create empty array to avoid complains by React
                        questionSelectionsMap[questionId].fields = []
                    }
                });


                setSelections(questionSelectionsMap);


            }
        });
    }, []);

    const calculateTrueConditions = (...conditions) => {
        return conditions.reduce((acc, value) => {
            if (value) {
                return acc + 1;
            }
            return acc;
        }, 0);
    };

    const fieldIncludedInSelection = (selection,desired_choice_answer_id) => {
        var check=false;
        for(var ii=0;ii < selection.fields.length;ii++){
            if(selection.fields[ii].choice_answer_id == desired_choice_answer_id)
                check=true
        }
        return check
    }

    const canEatGluten = () => {
        return !(
            selections[8] && selections[9]
            && fieldIncludedInSelection(selections[8],103)
            && fieldIncludedInSelection(selections[9],111)
        );
    };

    // console.log(selections);

    const waterConditions = () => {
        if (selections[7] && (
            selections[7].subQuestions[10].choice_answer_id === 24 ||
            selections[7].subQuestions[11].choice_answer_id === 24 ||
            selections[7].subQuestions[12].choice_answer_id === 24
        )) {
            return 1;
        }
        else if (selections[7] && (
            selections[7].subQuestions[10].choice_answer_id === 23 ||
            selections[7].subQuestions[11].choice_answer_id === 23 ||
            selections[7].subQuestions[12].choice_answer_id === 23
        )) {
            return 2;
        }
        else if (selections[7] && (
            selections[7].subQuestions[10].choice_answer_id === 22 ||
            selections[7].subQuestions[11].choice_answer_id === 22 ||
            selections[7].subQuestions[12].choice_answer_id === 22
        )) {
            return 3;
        }
        else {
            return 4;
        }
    }



    const hasNovaCondition = () => {
        return (selections[6]
            && [13, 14].includes(selections[6].subQuestions[22].choice_answer_id)
            && [13, 14].includes(selections[6].subQuestions[25].choice_answer_id)
            && [13, 14].includes(selections[6].subQuestions[21].choice_answer_id)
            && [13, 14].includes(selections[6].subQuestions[11].choice_answer_id)
            && [13, 14].includes(selections[6].subQuestions[12].choice_answer_id)
            && [13, 14].includes(selections[6].subQuestions[26].choice_answer_id)
            && [13, 14].includes(selections[6].subQuestions[28].choice_answer_id)
            && [13, 14].includes(selections[6].subQuestions[5].choice_answer_id)
            && [13, 14].includes(selections[6].subQuestions[10].choice_answer_id)
            && [13, 14].includes(selections[6].subQuestions[24].choice_answer_id)
            && [13, 14].includes(selections[6].subQuestions[18].choice_answer_id));
    };

    const hasRestoCondition = () => {
        return (selections[13]
            && (parseInt(selections[13].subQuestions[1].choice_answer_id)
            + parseInt(selections[13].subQuestions[2].choice_answer_id)
            + parseInt(selections[13].subQuestions[3].choice_answer_id)
            + parseInt(selections[13].subQuestions[4].choice_answer_id)) <= 185);
    }


    // console.log(selections)

    const getSections = () => {
        return (
            <section style={{marginTop: "55px"}} className="section">
                <div style={{backgroundColor: "white", borderRadius: "8px", padding: "0 40px 50px 40px"}} className="container">
                    <div className="titleContainer">
                        <h1 className="reportTitle has-text-info-dark">Rapport sur tes choix et habitudes alimentaires</h1>
                    </div>
                    {(selections[2] && (selections[2].choice_answer_id === 88 || selections[2].choice_answer_id === 89)) ?
                        <div className="content has-text-link">
                            <p className="reportText">Comme tu l’as mentionné, l’alimentation a bel et bien une influence importante sur ta santé. 
                                Tu juges que ta santé est 
                                {" "}{selections[1].content.toLowerCase()}{" "} 
                                et que tes choix et habitudes alimentaires sont
                                {" "}{selections[3].content.toLowerCase()}.
                                Ce rapport t’aidera à réaliser une réflexion sur ton alimentation et à agir pour en améliorer certains aspects, 
                                un pas à la fois, et ce, pour favoriser une meilleure santé.</p>
                        </div> : null }
                    {(selections[2] && selections[2].choice_answer_id === 90) ?
                    <div className="content has-text-link">
                        <p className="reportText">Tu considères comme relativement importante l’influence de ton alimentation sur ta santé. 
                            Tes choix alimentaires ont assurément un impact important sur ta santé. Tu juges que ta santé est
                            {" "}{selections[1].content.toLowerCase()}{" "} 
                            et que tes choix et habitudes alimentaires sont
                            {" "}{selections[3].content.toLowerCase()}{" "}. 
                            Ce rapport t’aidera à réaliser une réflexion sur ton alimentation et à agir pour en améliorer certains aspects, 
                            un pas à la fois, et ce, pour favoriser une meilleure santé.</p>
                    </div> : null }
                    {(selections[2] && (selections[2].choice_answer_id === 91 || selections[2].choice_answer_id === 92)) ?
                    <div className="content has-text-link">
                        <p className="reportText"> Tu ne considères pas que l’alimentation a une influence importante sur ta santé, 
                            alors que tes choix alimentaires ont assurément un impact important. Tu juges que ta santé est
                            {" "}{selections[1].content.toLowerCase()}{" "} 
                            et que tes choix et habitudes alimentaires sont
                            {" "}{selections[3].content.toLowerCase()}{" "}. 
                            Ce rapport t’aidera à réaliser une réflexion sur ton alimentation et à agir pour en 
                            améliorer certains aspects, un pas à la fois, et ce, pour favoriser une meilleure santé.</p>
                    </div> : null }
                    {((selections[2] && selections[2].choice_answer_id === 93)
                        || (selections[3] && selections[3].choice_answer_id === 99)
                        || (selections[1] && selections[1].choice_answer_id === 87)) ?
                    <div className="content has-text-link">
                        <p className="reportText">Tes choix alimentaires ont une influence importante sur ta santé. 
                            Ce rapport t’aidera à réaliser une réflexion sur ton alimentation et à agir
                             pour en améliorer certains aspects, un pas à la fois, et ce, pour favoriser une meilleure santé.   </p>
                    </div> : null }
                    <div className="content has-text-primary centeredPicture">
                        <p className="reportText">Pour aller plus loin, tu peux aussi consulter d’autres sources d’information sur l’alimentation!
                            <br/>
                            Attention cependant à la crédibilité de celle-ci. Méfie-toi lorsque…</p>
                        <div style={{width: "30%", minWidth: "200px"}}>
                            <figure className="image is-square is-fullwidth">
                                <img src={credibilite} alt="Image crédibilité" />
                            </figure>
                        </div>
                    </div>
                    <div style={{ padding: "10px 10px 10px 10px"}} className="has-background-info-light">
                        <h3 className="titlePourAllerPLusLoin has-text-info">Pour aller plus loin</h3>
                        <div className="content has-background-info-light">
                            <p className="textPourAllerPLusLoin">
                                <a href="https://www.sciencepresse.qc.ca/actualite/detecteur-rumeurs/2020/11/26/nutrition-8-questions-se-poser-devant-etudes"
                                    target="_blank">
                                    <span style={{marginRight: "8px"}} className="icon">
                                        <i className="fas fa-hand-point-right"></i>
                                    </span>
                                    <i>Nutrition : 8 questions à se poser devant les études</i>
                                </a>
                                <br />
                                <a href="https://www.sciencepresse.qc.ca/covid-19/comment-verifier-info-pro" target="_blank">
                                <span style={{marginRight: "8px"}} className="icon">
                                    <i className="fas fa-hand-point-right"></i>
                                </span>
                                    <i>Comment vérifier l’info comme un pro</i>
                                </a></p>
                        </div>
                    </div>
                    <div className="titleContainer">
                        <h1 className="reportTitle has-text-primary-dark">
                            Section 1 : Tes choix alimentaires 
                        </h1>
                    </div>
                    <div className="content has-text-primary">
                        <p className="reportText">
                            Dans cette première section, tu pourras prendre conscience de ce que tu manges et mieux répondre à la question Quoi manger? <br/>
                            Le Guide alimentaire canadien mentionne que la variété est tout aussi importante que la proportion de chacun des groupes alimentaires.<br/>
                             Cette image inspirée de l’assiette santé proposée par le Guide alimentaire canadien permet d’imager ces concepts de proportions et de variété.
                             Les prochains paragraphes détaillent les recommandations nutritionnelles qui s’appliquent à toi concernant cette assiette santé de même que les boissons et les aliments ultra-transformés.
                        </p>
                    </div>
                    <div className="centeredPicture">
                        <div style={{width: "40%", minWidth: "275px"}}>
                            <figure className="image is-square is-fullwidth">
                                <img src={assiete} alt="Image des groupes alimentaires" />
                            </figure>
                        </div>
                    </div>
                    <div style={{ padding: "10px 10px 10px 10px"}} className="has-background-info-light">
                        <h3 className="titlePourAllerPLusLoin has-text-info">Pour aller plus loin</h3>
                        <div className="content has-background-info-light">
                            <p className="textPourAllerPLusLoin">
                                <a href="https://guide-alimentaire.canada.ca/fr/recettes/" target="_blank">
                                    <span style={{marginRight: "8px"}} className="icon">
                                        <i className="fas fa-hand-point-right"></i>
                                    </span>
                                    <i>Idées de recettes saines</i>
                                </a>
                            </p>
                        </div>
                    </div>
                    <h2 style={{marginTop: "35px"}} className="reportSubtitle">Légumes et fruits</h2>
                    <div className="content has-text-primary">
                        <p className="reportText">
                            Les légumes et les fruits fournissent des fibres, vitamines, minéraux, composés phytochimiques et un pourcentage élevé en eau. 
                            Pour ces raisons, ils devraient avoir une place importante tous les jours dans ton alimentation. 
                        </p>
                    </div>
                    <h3 style={{marginTop: "35px"}} className="title3 has-text-info"><u>Proportion</u></h3>
                    <div className="content has-text-primary-dark">
                        <p className="reportText">
                            {
                                (selections[4] && [1, 2].includes(selections[4].subQuestions[1].choice_answer_id)) ?
                                `Moins de 25 % de tes repas et collations sont composés de légumes et fruits, 
                                c’est trop peu. Inclus-les davantage dans ton alimentation, vise 50 % de celle-ci.` : ""
                            }
                            {
                                (selections[4] && selections[4].subQuestions[1].choice_answer_id === 3) ?
                                `Les légumes et les fruits doivent être plus présents dans ton alimentation. 
                                Vise 50 % de tes repas et collations sous forme de légumes et fruits.` : ""
                            }
                            {
                                (selections[4] && selections[4].subQuestions[1].choice_answer_id === 4) ?
                                `Continue d’inclure autant de légumes et fruits dans ton alimentation, soit 50 % des repas et collations.` : ""
                            }
                            {
                                (selections[4] && [5, 6].includes(selections[4].subQuestions[1].choice_answer_id)) ?
                                `Continue de privilégier les légumes et fruits dans ton alimentation, mais vise plutôt 50 % des repas 
                                et collations et non plus, pour t’assurer d’inclure assez d’aliments protéinés et à grains entiers.` : ""
                            }
                        </p>
                    </div>
                    <h3 style={{marginTop: "35px"}} className="title3 has-text-info"><u>Variété</u></h3>
                    <div className="content has-text-primary">
                        <p className="reportText">
                            Les pigments responsables de la couleur des légumes et des fruits renferment chacun
                            des éléments bénéfiques pour la santé : vitamines, minéraux et composés antioxydants.
                        </p>
                        <p className="reportText has-text-primary-dark">
                            {
                                (selections[5] && [7, 8, 9].includes(selections[5].subQuestions[1].choice_answer_id)) ?
                                `Varie ce groupe alimentaire en y incluant un arc-en-ciel de couleurs pour le plaisir et la santé.` : ""
                            }
                            {
                                (selections[5] && [10, 11].includes(selections[5].subQuestions[1].choice_answer_id)) ?
                                `Continue de varier les légumes et fruits en incluant un arc-en-ciel de couleurs pour le plaisir et la santé.` : ""
                            }
                            {
                                (selections[5] && [12].includes(selections[5].subQuestions[1].choice_answer_id)) ?
                                `Assure-toi d’avoir un arc-en-ciel de couleurs dans ce groupe alimentaire pour le plaisir et la santé.` : ""
                            }
                        </p>
                        <p className="reportText">
                            Inspire-toi de ce tableau pour varier davantage ce groupe alimentaire.
                        </p>
                        <div>
                            <div style={{width: "60%", minWidth: "275px"}}>
                                <figure className="image is-square is-fullwidth">
                                    <img src={TableauDesLegumesFruits} alt="Image de divers légumes et fruits" />
                                </figure>
                            </div>
                        </div>
                    </div>

                    <h3 style={{marginTop: "35px"}} className="title3 has-text-info"><u>Pour faire de meilleurs choix</u></h3>
                    <div className="content  has-text-primary">
                        <p className="reportText">
                            Toutes les façons sont bonnes pour les manger : frais, cuits, surgelés, en compote, en conserve, prêts à manger, séchés.
                            <br />
                            <br />
                            <u>Important</u> : Les jus de fruits purs à 100 % ne sont pas considérés comme une portion de fruits. 
                            Ils sont dépourvus des fibres normalement présentes dans le fruit entier. 
                            Les fibres ralentissent l’absorption des sucres dans l’organisme et permettent de mieux contrôler la glycémie.  
                        </p>
                    </div>
                    <div className="centeredPicture">
                        <div className="innerPicture" style={{width: "75%"}}>
                            <img src={fruitsLegumes} alt="Image savais-tu fruits" />
                        </div>
                    </div>
                    <h2 style={{marginTop: "35px"}} className="reportSubtitle">Aliments protéinés</h2>
                    <div className="content  has-text-primary">
                        <p className="reportText">
                            Toutes les cellules de notre corps contiennent des protéines. 
                            Pour tirer le maximum de ce groupe, le Guide alimentaire canadien suggère de consommer une plus grande proportion 
                            d’aliments protéinés d’origine végétale qu’animale. 
                            Ces aliments d’origine végétale contiennent des fibres (sauf le tofu) et peu de gras saturés, ce qui est bénéfique pour la santé cardiovasculaire. 
                        </p>
                    </div>

                    <h3 style={{marginTop: "35px"}} className="title3 has-text-info"><u>Proportion</u></h3>

                    <div className="content has-text-primary">
                        <p className="reportText has-text-primary-dark">
                            {
                                (selections[4] && 
                                (parseInt(selections[4].subQuestions[2].choice_answer_id) +
                                parseInt(selections[4].subQuestions[3].choice_answer_id) === 4)) ?
                                `Continue de viser ¼ de tes repas et collations en aliments protéinés. ` : ""
                            }
                            {
                                (selections[4] && 
                                    (parseInt(selections[4].subQuestions[2].choice_answer_id) +
                                    parseInt(selections[4].subQuestions[3].choice_answer_id) > 4)) ?
                                `Vise ¼ de tes repas et collations en aliments protéinés et non plus. ` : ""
                            }
                            {
                                (selections[4] && 
                                    (parseInt(selections[4].subQuestions[2].choice_answer_id) +
                                    parseInt(selections[4].subQuestions[3].choice_answer_id) < 4)) ?
                                `Inclus ¼ d’aliments protéinés dans ton alimentation et non moins. ` : ""
                            }
                            {
                                (selections[4] && 
                                    (parseInt(selections[4].subQuestions[2].choice_answer_id) <=
                                    parseInt(selections[4].subQuestions[3].choice_answer_id))) ?
                                `Favorise davantage les aliments protéinés d’origine végétale dans ton alimentation, 
                                par exemple en participant au ` : ""
                            }
                            {
                                (selections[4] && 
                                    (parseInt(selections[4].subQuestions[2].choice_answer_id) <=
                                    parseInt(selections[4].subQuestions[3].choice_answer_id))) ?
                                    <a href="https://www.lundisansviande.net/category/recettes/" target="_blank">lundi sans viande.</a>
                                    : null
                            }
                        </p>
                    </div>

                    <h3 style={{marginTop: "35px"}} className="title3 has-text-info"><u>Variété</u></h3>

                    <div className="content has-text-primary">
                        <p className="reportText has-text-primary-dark">

                            {
                                (selections[5] && [7,8,9].includes(selections[5].subQuestions[2].choice_answer_id)) ?
                                `Varie davantage les aliments protéinés d’origine végétale.` : ""
                            }

                            {
                                (selections[5] && [10, 11].includes(selections[5].subQuestions[2].choice_answer_id)) ?
                                (selections[8] && ( fieldIncludedInSelection(selections[8],100) || fieldIncludedInSelection(selections[8],101) ) ?
                                `Continue de diversifier les aliments protéinés d’origine végétale. 
                                C’est d’autant plus important vu ton végétarisme/végétalisme.`
                                : `Continue de diversifier les aliments protéinés d’origine végétale.`) : ""
                            }

                            {
                                (selections[5] && [7,8,9].includes(selections[5].subQuestions[3].choice_answer_id)
                                && (selections[8] && (!fieldIncludedInSelection(selections[8],100) && !fieldIncludedInSelection(selections[8],101) ))) ?
                                `Varie davantage les aliments protéinés d’origine animale.` : ""
                            }

                            {
                                (selections[5] && [10, 11].includes(selections[5].subQuestions[3].choice_answer_id)) ?
                                `Continue de varier les aliments protéinés d’origine animale.` : ""
                            }

                            {
                                (selections[5] && [12].includes(selections[5].subQuestions[2].choice_answer_id)) ?
                                `Assure-toi de varier les aliments protéinés d’origine végétale.` : ""
                            }
                            {
                                (selections[5] && [12].includes(selections[5].subQuestions[3].choice_answer_id)) ?
                                `Assure-toi de varier les aliments protéinés d’origine animale.` : ""
                            }
                        </p>
                    </div>
                    <div className="doublePicture">
                        <div style={{width: "35%", minWidth: "275px"}}>
                            <img src={exportation} alt="Image canada et lentilles" />
                        </div>
                        <div style={{width: "33%", minWidth: "275px"}}>
                            <img src={legumineuses} alt="Image canada et légumineuses" />
                        </div>
                    </div>
                    <div className="content has-text-primary">
                        <p className="reportText has-text-primary-dark">
                            {(
                                (selections[6] && [13, 14, 15].includes(selections[6].subQuestions[3].choice_answer_id)
                                && [13, 14, 15].includes(selections[6].subQuestions[4].choice_answer_id))
                                ||
                                (selections[6] && [16, 17, 18].includes(selections[6].subQuestions[3].choice_answer_id)
                                && [13, 14, 15].includes(selections[6].subQuestions[4].choice_answer_id))
                                ||
                                (selections[6] && [13, 14, 15].includes(selections[6].subQuestions[3].choice_answer_id)
                                && [16, 17, 18].includes(selections[6].subQuestions[4].choice_answer_id))
                                ||
                                (selections[6] && [13, 14, 15].includes(selections[6].subQuestions[13].choice_answer_id)
                                && [13, 14, 15].includes(selections[6].subQuestions[14].choice_answer_id))
                                ||
                                (selections[6] && [13, 14, 15].includes(selections[6].subQuestions[3].choice_answer_id)
                                && [13, 14, 15].includes(selections[6].subQuestions[4].choice_answer_id))
                                ||
                                (selections[6] && [13, 14, 15].includes(selections[6].subQuestions[15].choice_answer_id))
                                ||
                                (selections[6] && [13, 14].includes(selections[6].subQuestions[1].choice_answer_id)
                                && [13, 14].includes(selections[6].subQuestions[2].choice_answer_id))
                                ||
                                (selections[6] && [13, 14].includes(selections[6].subQuestions[1].choice_answer_id)
                                && [15, 16, 17, 18].includes(selections[6].subQuestions[2].choice_answer_id))
                                ||
                                (selections[6] && [15, 16, 17, 18].includes(selections[6].subQuestions[1].choice_answer_id)
                                && [13, 14].includes(selections[6].subQuestions[2].choice_answer_id))
                                ||
                                (selections[6] && [13, 14].includes(selections[6].subQuestions[9].choice_answer_id))
                                ||
                                (selections[6] && [13, 14, 15].includes(selections[6].subQuestions[6].choice_answer_id))
                            )
                            ? `N’hésite pas à inclure ces aliments protéinés dans ton alimentation pour un apport varié en nutriments et pour le plaisir! 
                                Consulte la section pour aller plus loin pour des idées de recettes.`
                            : null
                            }
                        </p>
                        <ul className="reportText has-text-primary-dark">
                            {
                                (selections[6] && [13, 14, 15].includes(selections[6].subQuestions[3].choice_answer_id)
                                && [13, 14, 15].includes(selections[6].subQuestions[4].choice_answer_id)) ?
                                <li key="1_1">Légumineuses, tofu, tempeh, protéines végétales texturées (PVT), edamames</li>
                                 : null
                            }
                            {
                                (selections[6] && [16, 17, 18].includes(selections[6].subQuestions[3].choice_answer_id)
                                && [13, 14, 15].includes(selections[6].subQuestions[4].choice_answer_id)) ?
                                <li key="1_2">Tofu, tempeh, protéines végétales texturées (PVT), edamames</li> : null
                            }
                            {
                                (selections[6] && [13, 14, 15].includes(selections[6].subQuestions[3].choice_answer_id)
                                && [16, 17, 18].includes(selections[6].subQuestions[4].choice_answer_id)) ?
                                <li key="1_3">Légumineuses</li> : null
                            }
                            {
                                (selections[6] && [13, 14, 15].includes(selections[6].subQuestions[13].choice_answer_id)
                                && [13, 14, 15].includes(selections[6].subQuestions[14].choice_answer_id)) ?
                                <li key="1_4">Fromage, idéalement faible en matières grasses (≤ 20 % M.G.).</li> : null
                            }
                            {/*{*/}
                            {/*    (selections[6] && [13, 14, 15].includes(selections[6].subQuestions[3].choice_answer_id)*/}
                            {/*    && [13, 14, 15].includes(selections[6].subQuestions[4].choice_answer_id)) ?*/}
                            {/*    <li key="1_5">Légumineuses, tofu, tempeh, protéines végétales texturées (PVT), edamames</li> : null*/}
                            {/*}*/}
                            {
                                (selections[6] && [13, 14, 15].includes(selections[6].subQuestions[15].choice_answer_id)) ?
                                <li key="1_6">Yogourt, idéalement nature.</li> : null
                            }
                            {
                                (selections[6] && [13, 14].includes(selections[6].subQuestions[1].choice_answer_id)
                                && [13, 14].includes(selections[6].subQuestions[2].choice_answer_id)) ?
                                <li key="1_7">Poisson et fruits de mer</li> : null
                            }
                            {
                                (selections[6] && [13, 14].includes(selections[6].subQuestions[1].choice_answer_id)
                                && [15, 16, 17, 18].includes(selections[6].subQuestions[2].choice_answer_id)) ?
                                <li key="1_8">Poisson</li> : null
                            }
                            {
                                (selections[6] && [15, 16, 17, 18].includes(selections[6].subQuestions[1].choice_answer_id)
                                && [13, 14].includes(selections[6].subQuestions[2].choice_answer_id)) ?
                                <li key="1_9">Fruits de mer</li> : null
                            }
                            {
                                (selections[6] && [13, 14].includes(selections[6].subQuestions[9].choice_answer_id)) ?
                                <li key="1_10">Oeufs</li> : null
                            }
                            {
                                (selections[6] && [13, 14, 15].includes(selections[6].subQuestions[6].choice_answer_id)) ?
                                <li key="1_11">Arachides, noix et graines non salées</li> : null
                            }
                        </ul>
                    </div>
                    <div className="content">
                        {
                            (selections[8] && fieldIncludedInSelection(selections[8],101) ) ?
                            <p className="reportText has-text-primary">
                                Réfère-toi au tableau pour t’inspirer à varier davantage ce groupe alimentaire.
                            </p>
                            : <p className="reportText has-text-primary">
                                Inspire-toi de ce tableau pour varier ce groupe alimentaire tout en respectant ta pratique alimentaire.
                            </p>
                        }
                    </div>

                    <div>
                        <div className="innerPicture" style={{width: "75%"}}>
                            <img src={
                                selections[8] && fieldIncludedInSelection(selections[8],101) ? TableauAlimentsProteinesSi8b : TableauAlimentsProteines
                            } alt="Aliments protéinés" />
                        </div>
                    </div>

                    <h3 style={{marginTop: "35px"}} className="title3 has-text-info"><u>Pour faire de meilleurs choix</u></h3>
                    <div className="content">
                        {
                            (selections[6] 
                                && [16, 17, 18].includes(selections[6].subQuestions[3].choice_answer_id)
                                && [16, 17, 18].includes(selections[6].subQuestions[4].choice_answer_id)) ?
                            <p className="reportText has-text-primary">
                                <b>Légumineuses, tofu et compagnie</b><br />
                                Lorsque tu choisis ton tofu, recherche la présence de chlorure 
                                de calcium ou de sulfate de calcium pour augmenter ton apport en calcium.
                            </p>
                            : null
                        }
                        {
                            (selections[6] 
                                && ([16, 17, 18].includes(selections[6].subQuestions[13].choice_answer_id)
                                || [16, 17, 18].includes(selections[6].subQuestions[14].choice_answer_id))) ?
                            <p className="reportText has-text-primary">
                                <b>Fromage</b><br />
                                Choisis les fromages à faible teneur en matières grasses (moins de 20 % matières grasses) et plus faibles en sodium. <br />
                                Moins gras : P’tit Québec mozzarella partiellement écrémée, cottage, feta léger, ricotta, bocconcini, brie léger, etc. 
                                <br />
                                Moins salé : fromage frais (ricotta, bocconcini, etc.), fromage de chèvre, etc.
                            </p>
                            : null
                        }
                        {
                            (selections[6] 
                                && ([16, 17, 18].includes(selections[6].subQuestions[15].choice_answer_id))) ?
                            <p className="reportText has-text-primary">
                                <b>Yogourt</b><br />
                                Choisis un yogourt nature. Pour s’y habituer, aromatise-le avec des fruits ou mélange-le avec un peu de yogourt aromatisé.
                            </p>
                            : null
                        }
                        {
                            (selections[6] 
                                && ([16, 17, 18].includes(selections[6].subQuestions[7].choice_answer_id))) ?
                            <p className="reportText has-text-primary">
                                <b>Viande rouge</b><br />
                                Limite ta consommation et choisis des coupes de viandes maigres (voir la section pour aller plus loin).
                            </p>
                            : null
                        }
                        {
                            (selections[6] 
                                && ([15, 16, 17, 18].includes(selections[6].subQuestions[1].choice_answer_id)
                                && [15, 16, 17, 18].includes(selections[6].subQuestions[2].choice_answer_id))) ?
                            <p className="reportText has-text-primary">
                                <b>Poissons et fruits de mer</b><br />
                                Choisis du poisson frais ou en conserve avec peu ou pas de sodium et des produits de la mer frais. 
                                Évite les versions frites, panées ou enrobées de pâte.
                            </p>
                            : null
                        }
                        {
                            (selections[6] 
                                && ([16, 17].includes(selections[6].subQuestions[6].choice_answer_id))) ?
                            <p className="reportText has-text-primary">
                                <b>Arachides, noix et graines</b><br />
                                Source de bon gras, de fibres et d’antioxydants, choisis-les sans sel et les beurres naturels, sans sucre ni additifs.
                            </p>
                            : null
                        }
                        {
                            (selections[6] 
                            && ([18].includes(selections[6].subQuestions[6].choice_answer_id))) ?
                            <p className="reportText has-text-primary">
                                <b>Arachides, noix et graines</b><br />
                                Source de bon gras, de fibres et d’antioxydants, choisis-les sans sel et les beurres naturels, sans sucre ni additifs.
                                <br />
                                Bien qu’ils soient sains, consomme-les avec modération.
                            </p>
                            : null
                        }
                        {
                            (selections[6] && selections[7]
                                && selections[6].subQuestions[13].choice_answer_id === 13
                                && selections[6].subQuestions[14].choice_answer_id === 13
                                && selections[6].subQuestions[15].choice_answer_id === 13
                                && selections[7].subQuestions[1].choice_answer_id === 19
                                && selections[7].subQuestions[2].choice_answer_id === 19
                                && selections[7].subQuestions[3].choice_answer_id === 19
                                && selections[7].subQuestions[4].choice_answer_id === 19
                                && selections[7].subQuestions[5].choice_answer_id === 19
                                && selections[7].subQuestions[6].choice_answer_id === 19) ?
                            <p className="reportText has-text-primary">
                                <b>Aliments et breuvages riches en calcium</b><br />
                                Il semble que tu ne consommes pas de produits laitiers comme le fromage, le yogourt ou le lait, ni de boissons végétales. 
                                Ajoutes-en à ton alimentation ou assure-toi de manger des aliments riches en calcium : tofu enrichi de calcium, 
                                haricots noirs ou blancs, céréales et tortillas enrichis de calcium, 
                                amandes et beurre de sésame, légumes feuillus de la famille des brassicacées, figues séchées, etc. 
                            </p>
                            : null
                        }
                    </div>
                    <div className="centeredPicture">
                        <div className="innerPicture" style={{width: "75%"}}>
                            <img src={oeufs} alt="Savais-tu oeufs" />
                        </div>
                    </div>
                    <div className="centeredPicture">
                        <div className="innerPicture" style={{width: "75%"}}>
                            <img src={poissons} alt="Savais-tu surpêche" />
                        </div>
                    </div>
                    <div style={{ padding: "10px 10px 10px 10px"}} className="has-background-info-light">
                        <h3 className="titlePourAllerPLusLoin has-text-info">Pour aller plus loin</h3>
                        <div className="content has-background-info-light">
                            <p className="textPourAllerPLusLoin">
                                <a href="https://www.lundisansviande.net/category/recettes/"
                                    target="_blank">
                                    <span style={{marginRight: "8px"}} className="icon">
                                        <i className="fas fa-hand-point-right"></i>
                                    </span>
                                    <i>Lundi sans viande</i>
                                </a>
                                <br />
                                <a href="https://www.soscuisine.com/recette/categorie/vegetalienne" target="_blank">
                                <span style={{marginRight: "8px"}} className="icon">
                                    <i className="fas fa-hand-point-right"></i>
                                </span>
                                    <i>SOScuisine</i>
                                </a>
                                <br />
                                <a href="https://www.unemamanvegane.com/blog" target="_blank">
                                <span style={{marginRight: "8px"}} className="icon">
                                    <i className="fas fa-hand-point-right"></i>
                                </span>
                                    <i>Une maman végane</i>
                                </a>
                                <br />
                                <a href="https://www.lacuisinedejeanphilippe.com/recettes/" target="_blank">
                                <span style={{marginRight: "8px"}} className="icon">
                                    <i className="fas fa-hand-point-right"></i>
                                </span>
                                    <i>La cuisine de Jean-Philippe</i>
                                </a>
                                <br />
                                <a href="https://www.theppk.com/recipes/" target="_blank">
                                <span style={{marginRight: "8px"}} className="icon">
                                    <i className="fas fa-hand-point-right"></i>
                                </span>
                                    <i>Post Punk kitchen de Isa Chandra (en anglais)</i>
                                </a>
                                <br />
                                <a href="https://extenso.org/article/quelles-coupes-de-viandes-sont-les-plus-maigres/" target="_blank">
                                <span style={{marginRight: "8px"}} className="icon">
                                    <i className="fas fa-hand-point-right"></i>
                                </span>
                                    <i>Coupes de viandes maigres</i>
                                </a>
                                <br />
                                <a href="http://exploramer.qc.ca/fourchette-bleue/" target="_blank">
                                <span style={{marginRight: "8px"}} className="icon">
                                    <i className="fas fa-hand-point-right"></i>
                                </span>
                                    <i>Exploramer et produits du Saint-Laurent</i>
                                </a>
                                <br />
                                <a href="https://www.msc.org/fr/" target="_blank">
                                <span style={{marginRight: "8px"}} className="icon">
                                    <i className="fas fa-hand-point-right"></i>
                                </span>
                                    <i>Certification Marine Stewardship Council (MSC)</i>
                                </a>
                                <br />
                                <a href="https://ocean.org/" target="_blank">
                                <span style={{marginRight: "8px"}} className="icon">
                                    <i className="fas fa-hand-point-right"></i>
                                </span>
                                    <i>Certification Ocean Wise</i>
                                </a>
                                </p>
                        </div>
                    </div>
                    <h2 style={{marginTop: "35px"}} className="reportSubtitle">Aliments à grains entiers</h2>
                    {
                        !canEatGluten() ?
                        <div className="centeredPicture">
                            <p className="reportText has-text-primary-dark">
                            Vu ton diagnostic de maladie coeliaque, assure-toi de bien lire les ingrédients 
                            des différents produits que tu consommes pour éviter le gluten. Un suivi en nutrition peut t’aider dans ta démarche.
                            </p>
                            <div className="innerPicture" style={{width: "65%"}}>
                                <img src={fruitsLegumes} alt="Image sans gluten" />
                            </div>
                        </div> : null
                    }
                    {
                        (selections[8] && selections[9]
                        && fieldIncludedInSelection(selections[8],103)
                        && !fieldIncludedInSelection(selections[9],111) ) ?
                        <div className="content">
                            <p className="reportText has-text-primary-dark">
                            Suivre un régime sans gluten sans avoir eu un diagnostic de maladie cœliaque n’est pas recommandé. 
                            Puisque ce régime est restrictif et complexe, certaines carences nutritionnelles 
                            (fibres, vitamines et minéraux) peuvent s’en suivre. 
                            De plus, la consommation de gluten est requise pour diagnostiquer une maladie cœliaque. Si tu as des inquiétudes, 
                            un suivi médical et en nutrition est peut-être nécessaire.
                            <br /><br />
                            Consulte la
                            {" "}<a href="https://opdq.org/wp-content/uploads/2018/09/RegimeSansGluten_2013.pdf" target="_blank">
                            prise de position de l’ordre des diététistes nutritionnistes du Québec
                            </a>{" "}.
                            </p>
                        </div> : null
                    }
                    {
                        canEatGluten() ?
                        <div>
                            <div className="content">
                                <p className="reportText has-text-primary-dark">
                                Les grains entiers gagnent à être connus pour leur contenu en fibres, glucides complexes, vitamines et minéraux. 
                                Ceux-ci contiennent tous les éléments du grain, contrairement aux grains raffinés qui perdent le son, et donc les fibres, 
                                et le germe lors du processus de transformation. Or, c’est dans le germe qu’on retrouve une bonne partie des vitamines et 
                                minéraux.<br /><br />
                                La majorité des Canadiennes et Canadiens consomment seulement la moitié de leurs besoins en fibres. 
                                Pourtant, celles-ci sont bénéfiques pour diminuer les risques de cancer du côlon, diabète de type 2, maladies du cœur, 
                                accident vasculaire cérébral et constipation. 
                                </p>
                            </div>
                        </div>
                        : null

                    }

                    <h3 style={{marginTop: "35px"}} className="title3 has-text-info"><u>Proportion</u></h3>

                    <div className="content">
                        {
                            (canEatGluten() && selections[6]
                                && [13, 14, 15, 16].includes(selections[6].subQuestions[19].choice_answer_id)) ?
                                <p className="reportText has-text-primary-dark">
                                    Consomme des grains entiers chaque jour et augmente jusqu’au ¼ de tes repas et collations comblés par ces aliments. 
                                </p> : null
                        }
                        {
                            (canEatGluten() && selections[6]
                                && [17, 18].includes(selections[6].subQuestions[19].choice_answer_id)) ?
                                <p className="reportText has-text-primary-dark">
                                    Continue d’inclure des grains entiers chaque jour et vise ¼ de tes repas et collations avec ces aliments. 
                                </p> : null
                        }
                        {
                            (canEatGluten() && selections[4]
                                && (selections[4].subQuestions[4].choice_answer_id <= selections[4].subQuestions[5].choice_answer_id)) ?
                                <p className="reportText has-text-primary-dark">
                                    Vise 100 % d’aliments à grains entiers et limite autant que possible les aliments à grains raffinés.
                                    <br />
                                <b>Un truc :</b> au départ, choisis au moins la moitié des produits céréaliers à grains entiers et remplace la 
                                moitié de la farine blanche par de la farine entière dans tes recettes.
                                </p> : null
                        }
                        {
                            (canEatGluten() && selections[4]
                                && (selections[4].subQuestions[4].choice_answer_id > selections[4].subQuestions[5].choice_answer_id)) ?
                                <p className="reportText has-text-primary-dark">
                                    Continue de consommer une majorité de grains entiers. 
                                    Essaie de viser 100 % d’aliments à grains entiers en limitant autant que possible les aliments à grains raffinés.
                                </p> : null
                        }
                        {
                            (canEatGluten() && selections[4] && selections[5]
                                && selections[4].subQuestions[4].choice_answer_id !== 1 && selections[4].subQuestions[5].choice_answer_id === 1) ?
                                <p className="reportText has-text-primary-dark">
                                    Continue de consommer que des aliments à grains entiers et aucun grain raffiné. 
                                </p> : null
                        }
                    </div>
                    <div className="leftPicture">
                        <p className="reportText has-text-primary">
                            <b>Qu’est-ce qu’un grain entier?</b>
                        </p>
                        <div style={{width: "65%", minWidth: "275px", paddingTop: "12px"}}>
                            <img src={grains} alt="Image grains" />
                        </div>
                    </div>
                    {
                        canEatGluten() ?
                        <h3 style={{marginTop: "35px"}} className="title3 has-text-info"><u>Variété</u></h3>
                        : null
                    }
                    <div className="content">
                        {
                            (canEatGluten() && selections[5]
                                && [7, 8, 9].includes(selections[5].subQuestions[4].choice_answer_id)) ?
                                <p className="reportText has-text-primary-dark">
                                    Varie les grains entiers que tu consommes pour le plaisir et de la diversité.
                                </p> : null
                        }
                        {
                            (canEatGluten() && selections[5]
                                && [10, 11].includes(selections[5].subQuestions[4].choice_answer_id)) ?
                                <p className="reportText has-text-primary-dark">
                                    Continue d’avoir une bonne variété de grains entiers dans ton alimentation.
                                </p> : null
                        }
                        {
                            (canEatGluten() && selections[5]
                                && [12].includes(selections[5].subQuestions[4].choice_answer_id)) ?
                                <p className="reportText has-text-primary-dark">
                                    Assure-toi de varier les aliments à grains entiers dans ton alimentation pour le plaisir et de la diversité.
                                </p> : null
                        }
                    </div>
                    {
                        canEatGluten() ? 
                        <div className="leftPicture">
                            <p className="reportText has-text-primary">
                                <b>Comment s’assurer qu’un aliment est à base de grains entiers?</b>
                            </p>
                            <div style={{width: "65%", paddingTop: "15px"}}>
                                <img src={cereale} alt="Image cereales" />
                            </div>
                        </div> : null
                    }
                    <h2 style={{marginTop: "35px"}} className="reportSubtitle">Aliments ultra-transformés</h2>
                    <div className="content">
                        <p className="reportText has-text-primary">
                        La classification NOVA permet de séparer les différents aliments dans quatre groupes distincts selon leur degré de transformation. 
                        Dans le groupe 4, on y retrouve les aliments ultra-transformés.
                        </p>
                        <div className="leftPicture">
                            <div style={{width: "50%"}}>
                                <img src={classificationNova} alt="Image groupes ultra-transformés" />
                            </div>
                        </div>
                        <p className="reportText has-text-primary-dark">
                        {
                            hasNovaCondition() ?
                                `Continue d’éviter ces aliments, car ils sont riches en gras, sel, sucre et additifs. 
                                Puisque chaque aliment peut faire partie d’une saine alimentation, 
                                tu peux en consommer exceptionnellement pour compléter une alimentation équilibrée et variée.`
                             : `Ceux-ci sont riches en gras, sel, sucre et additifs. Tout aliment peut faire partie d’une saine alimentation, 
                                mais on mange ceux-ci exceptionnellement pour compléter une alimentation 
                                équilibrée et variée. À toi de voir si tu peux réduire ta consommation de ces aliments que tu manges fréquemment :`
                        }
                        </p>
                        <ul>
                            {
                                (!hasNovaCondition() && selections[6]
                                && [15, 16, 17, 18].includes(selections[6].subQuestions[22].choice_answer_id)) ?
                                <li key="2_1">
                                    <p className="reportText has-text-primary-dark">
                                    Grignotines ou croustilles riches en gras → favorise les versions faibles en gras
                                    </p>
                                </li> : null
                            }
                            {
                                (!hasNovaCondition() && selections[6]
                                && [15, 16, 17, 18].includes(selections[6].subQuestions[25].choice_answer_id)) ?
                                <li key="2_2">
                                    <p className="reportText has-text-primary-dark">
                                    Pâtisseries et autres produits céréaliers sucrés et riches en gras
                                    </p>
                                </li> : null
                            }
                            {
                                (!hasNovaCondition() && selections[6]
                                && [15, 16, 17, 18].includes(selections[6].subQuestions[21].choice_answer_id)) ?
                                <li key="2_3">
                                    <p className="reportText has-text-primary-dark">
                                    Tartinades sucrées
                                    </p>
                                </li> : null
                            }
                            {
                                (!hasNovaCondition() && selections[6]
                                && [15, 16, 17, 18].includes(selections[6].subQuestions[11].choice_answer_id)) ?
                                <li key="2_4">
                                    <p className="reportText has-text-primary-dark">
                                    Aliments préfrits ou frits
                                    </p>
                                </li> : null
                            }
                            {
                                (!hasNovaCondition() && selections[6]
                                && [15, 16, 17, 18].includes(selections[6].subQuestions[12].choice_answer_id)) ?
                                <li key="2_5">
                                    <p className="reportText has-text-primary-dark">
                                    Pommes de terre frites et poutine
                                    </p>
                                </li> : null
                            }
                            {
                                (!hasNovaCondition() && selections[6]
                                && [15, 16, 17, 18].includes(selections[6].subQuestions[26].choice_answer_id)) ?
                                <li key="2_6">
                                    <p className="reportText has-text-primary-dark">
                                    Sucreries → favorise le chocolat noir avec ≥ 70 % de cacao
                                    </p>
                                </li> : null
                            }
                            {
                                (!hasNovaCondition() && selections[6]
                                && [15, 16, 17, 18].includes(selections[6].subQuestions[28].choice_answer_id)) ?
                                <li key="2_7">
                                    <p className="reportText has-text-primary-dark">
                                    Friandises glacées
                                    </p>
                                </li> : null
                            }
                            {
                                (!hasNovaCondition() && selections[6]
                                && [15, 16, 17, 18].includes(selections[6].subQuestions[5].choice_answer_id)) ?
                                <li key="2_8">
                                    <p className="reportText has-text-primary-dark">
                                    Aliments prêt-à-manger à base de protéines végétales
                                    </p>
                                </li> : null
                            }
                            {
                                (!hasNovaCondition() && selections[6]
                                && [15, 16, 17, 18].includes(selections[6].subQuestions[10].choice_answer_id)) ?
                                <li key="2_9">
                                    <p className="reportText has-text-primary-dark">
                                    Charcuteries
                                    </p>
                                </li> : null
                            }
                        </ul>
                    </div>
                    <div style={{paddingTop: "30px"}} className="content">
                        <p className="reportText has-text-primary">
                            {
                                (selections[6] && selections[6].subQuestions[24].choice_answer_id === 13
                                    && selections[6].subQuestions[18].choice_answer_id === 13) ? null :
                                `La valeur nutritive de certains aliments peut varier énormément. Pour faire de meilleurs choix, 
                                vise le moins de sucre possible par portion et pour les: `
                            }
                        </p>
                        <ul>
                        {
                            (selections[6] && [14, 15, 16, 17, 18].includes(selections[6].subQuestions[24].choice_answer_id)) ?
                            <li key="3_1">
                                <p className="reportText has-text-primary-dark">
                                    barres de céréales du commerce sucrées, vise ≥ 3 g de fibres et de protéines par barre. 
                            Choisis à base de noix, graines, fruits séchés et avec des 
                            ingrédients simples et entiers comme des flocons d’avoine ou de la farine de grains entiers.
                            </p></li> : null
                        }
                        {
                            (selections[6] && [14, 15, 16, 17, 18].includes(selections[6].subQuestions[18].choice_answer_id)) ?
                            <li key="3_2">
                                <p className="reportText has-text-primary-dark">
                                    céréales à déjeuner, vise ≥ 4 g de fibres* et de protéines par tasse.
                                    </p></li> : null
                        }
                        </ul>
                        <p className="reportText has-text-primary" style={{fontSize:'1em'}}>
                            {
                                (selections[6] && selections[6].subQuestions[24].choice_answer_id === 13
                                    && selections[6].subQuestions[18].choice_answer_id === 13) ? null :
                                `*Attention à l’ajout de fibres nouvelles (inuline + bale d’avoine). 
                                Elles augmentent le pourcentage de fibres, mais n’ont pas les mêmes bienfaits que les grains entiers.`
                            }
                        </p>
                        <p className="reportText has-text-primary">
                            Réfère-toi à la section suivante sur les boissons afin de porter un regard critique sur ta consommation de boissons ultra-transformées.
                        </p>
                    </div>
                    <h2 className="reportSubtitle">Boissons</h2>
                    <div className="content">
                        <p className="reportText has-text-primary">
                        L’eau, qu’elle soit du robinet ou en bouteille, est la boisson par excellence pour bien s’hydrater. 
                        On devrait s’y tourner la majorité du temps pour étancher notre soif. 
                        Réfère-toi à l’image suivante pour constater les boissons à prioriser.
                        </p>
                    </div>
                    <div className="centeredPicture">
                        <div className="innerPicture" style={{width: "50%"}}>
                            <img src={pichet} alt="Image boissons" />
                        </div>
                    </div>
                    <h3 className="title3 has-text-info">1. Eau</h3>
                    <div className="content">
                        <p className="reportText has-text-primary">
                        Il est important de boire de l’eau plusieurs fois par jour pour éviter la déshydratation qui peut entraîner mal de tête, 
                        problèmes de concentration, urines foncées, peau sèche, constipation, fatigue et crampes musculaires.
                        </p>
                        {
                            (waterConditions() === 1) ? 
                            <p className="reportText has-text-primary-dark">
                                Continue de boire de l’eau plusieurs fois par jour, chaque jour. 
                                L’eau naturelle, gazéifiée ou aromatisée sans sucre ni édulcorant sont des options saines qui permettent de varier.
                            </p> : null
                        }
                        {
                            (waterConditions() === 2) ? 
                            <p className="reportText has-text-primary-dark">
                                Bois de l’eau plusieurs fois par jour. L’eau naturelle, gazéifiée ou aromatisée 
                                sans sucre ni édulcorant sont des options saines qui permettent de varier. <br /><br />
                                Pour boire de l’eau régulièrement : traîne une bouteille d’eau réutilisable partout où tu vas, 
                                fais-toi des rappels sur ton téléphone ou utilise une application
                            </p> : null
                        }
                        {
                            (waterConditions() === 3) ? 
                            <p className="reportText has-text-primary-dark">
                                Bois de l’eau chaque jour ET plusieurs fois par jour. L’eau naturelle, gazéifiée ou aromatisée sans sucre 
                                ni édulcorant sont des options saines qui permettent de varier. <br /><br />
                                Pour boire de l’eau régulièrement : traîne une bouteille d’eau réutilisable partout où tu vas, 
                                fais-toi des rappels sur ton téléphone ou utilise une application. 
                            </p> : null
                        }
                        {
                            (waterConditions() === 4) ? 
                            <p className="reportText has-text-primary-dark">
                                Tu ne sembles pas consommer de l’eau très souvent. Assure-toi d’en boire plusieurs fois par jour. L’eau naturelle, 
                                gazéifiée ou aromatisée sans sucre ni édulcorant sont des options saines qui permettent de varier. <br /><br />
                                Pour boire de l’eau régulièrement : traîne une bouteille d’eau réutilisable partout où tu vas, 
                                fais-toi des rappels sur ton téléphone ou utilise une application. 
                            </p> : null
                        }
                    </div>
                    <div className="centeredPicture">
                        <div className="innerPicture" style={{width: "50%"}}>
                            <img src={varierEau} alt="Image varier votre eau" />
                        </div>
                    </div>
                    <div className="centeredPicture">
                        <div className="innerPicture" style={{width: "65%"}}>
                            <img src={bouteilleEau} alt="Image pollution des boutilles d'eau" />
                        </div>
                    </div>
                    <h3 className="title3 has-text-info">2. Thé et café non sucré</h3>
                    <div className="content">
                        <p className="reportText has-text-primary">
                            La limite quotidienne en caféine est de 400 mg par jour (~ 3 petites tasses de café). 
                        </p>
                    </div>
                    <div className="content">
                        {
                            (selections[7] 
                                && ([23, 24].includes(selections[7].subQuestions[20].choice_answer_id)
                                || [23, 24].includes(selections[7].subQuestions[21].choice_answer_id)
                                || [23, 24].includes(selections[7].subQuestions[19].choice_answer_id))) ?
                            <p className="reportText has-text-primary-dark">
                                Assure-toi de respecter cette limite à l'avenir. <br />
                                N’oublie pas que d’autres boissons contiennent aussi de la caféine.
                            </p> : null
                        }
                    </div>
                    <div className="centeredPicture">
                        <div className="innerPicture" style={{width: "80%"}}>
                            <img width="900px" src={boissonCafe} alt="Image boissons caféinées" />
                        </div>
                    </div>
                    <h3 className="title3 has-text-info">3. Lait et boisson végétale nature</h3>
                    <div className="content">
                        <p className="reportText has-text-primary">
                            Ces boissons sont nutritives et sans sucre ajouté. Après l’eau, elles sont de bons choix.
                        </p>
                        {
                            (selections[7] 
                                && [22, 23, 24].includes(selections[7].subQuestions[4].choice_answer_id)) ?
                            <p className="reportText has-text-primary-dark">
                                La boisson de soya enrichie est celle qui ressemble le plus au lait du point de vue de la valeur nutritive. 
                                Assure-toi de brasser vigoureusement le contenant puisque le calcium a tendance à se déposer au fond.
                            </p> : null
                        }
                        {
                            (selections[7] 
                                && [22, 23, 24].includes(selections[7].subQuestions[6].choice_answer_id)) ?
                            <p className="reportText has-text-primary-dark">
                                Les boissons végétales autres que celles de soya sont moins nutritives 
                                (moins de protéines par exemple), mais restent tout de même un bon choix pour s’hydrater.
                                Attention aux versions aromatisées qui peuvent contenir beaucoup de sucre.
                            </p> : null
                        }
                        {
                            (selections[7]
                                && (
                                    ([19, 20, 21].includes(selections[7].subQuestions[1].choice_answer_id)
                                    ^ [19, 20, 21].includes(selections[7].subQuestions[2].choice_answer_id))
                                    || [19, 20, 21].includes(selections[7].subQuestions[4].choice_answer_id)
                                    || [19, 20, 21].includes(selections[7].subQuestions[6].choice_answer_id)
                                    && ![22,23,24].includes(selections[7].subQuestions[4].choice_answer_id)
                                )

                            ) ?
                            <p className="reportText has-text-primary-dark">
                                Tu ne sembles pas consommer une variété de ces boissons régulièrement.
                                Tu peux, si tu le souhaites, en introduire dans ton alimentation pour varier davantage tes boissons. 
                                Note que la boisson de soya enrichie est celle qui ressemble le plus au lait du point de vue de la valeur nutritive.
                            </p> : null
                        }
                    </div>
                    <div className="centeredPicture">
                        <div className="innerPicture" style={{width: "65%"}}>
                            <img src={boissonSoya} alt="Image boissons soya" />
                        </div>
                    </div>

                    {/*<div style={{ padding: "10px 10px 10px 10px"}} className="has-background-info-light">*/}
                    {/*    <h3 className="titlePourAllerPLusLoin has-text-info">Pour aller plus loin</h3>*/}
                    {/*    <div className="content has-background-info-light">*/}
                    {/*        <p className="textPourAllerPLusLoin">*/}
                    {/*            <a href="https://unpointcinq.ca/agir/lait-animal-vegetal-quatre-verites-climatiques/" target="_blank">*/}
                    {/*                <span style={{marginRight: "8px"}} className="icon">*/}
                    {/*                    <i className="fas fa-hand-point-right"></i>*/}
                    {/*                </span>*/}
                    {/*                <i>Le lait et ses quatre vérités climatiques</i>*/}
                    {/*            </a>*/}
                    {/*        </p>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <h3 style={{marginTop: "40px"}} className="title3 has-text-info">4. Jus, lait aromatisé et boisson végétale aromatisée.<br/>Boissons sucrées contenant certains nutriments</h3>
                    <div className="content">
                        <p className="reportText has-text-primary">
                        Le jus de fruit pur à 100 %, le lait aromatisé, les boissons végétales aromatisées et les 
                        eaux vitaminées sucrées sont à consommer <b>à l’occasion</b> puisqu’elles ajoutent du sucre inutilement à l’alimentation.
                        </p>
                        <ul>
                            {
                                (selections[7] 
                                    && [19, 20].includes(selections[7].subQuestions[3].choice_answer_id)
                                    && [19, 20].includes(selections[7].subQuestions[5].choice_answer_id)
                                    && [19, 20].includes(selections[7].subQuestions[8].choice_answer_id)
                                    && [19, 20].includes(selections[7].subQuestions[14].choice_answer_id)) ?
                                <li key="4_1">
                                    <p className="reportText has-text-primary-dark">
                                    Continue de les limiter.
                                    </p>
                                </li> : null
                            }
                            {
                                (selections[7] &&
                                    (      [24].includes(selections[7].subQuestions[3].choice_answer_id)
                                        || [24].includes(selections[7].subQuestions[5].choice_answer_id)
                                        || [24].includes(selections[7].subQuestions[8].choice_answer_id)
                                        || [24].includes(selections[7].subQuestions[14].choice_answer_id)
                                    )
                                )?
                                <li key="4_1">
                                    <p className="reportText has-text-primary-dark">
                                    Tu consommes au moins une de ces boissons sucrées plusieurs fois par jour. Pense à réduire ta fréquence de consommation.
                                    </p>
                                </li> : null
                            }
                            {
                                (selections[7]
                                    &&
                                    (
                                           [23].includes(selections[7].subQuestions[3].choice_answer_id)
                                        || [23].includes(selections[7].subQuestions[5].choice_answer_id)
                                        || [23].includes(selections[7].subQuestions[8].choice_answer_id)
                                        || [23].includes(selections[7].subQuestions[14].choice_answer_id)
                                    )
                                    &&
                                    !(
                                           [24].includes(selections[7].subQuestions[3].choice_answer_id)
                                        || [24].includes(selections[7].subQuestions[5].choice_answer_id)
                                        || [24].includes(selections[7].subQuestions[8].choice_answer_id)
                                        || [24].includes(selections[7].subQuestions[14].choice_answer_id)
                                    )
                                )?
                                <li key="4_1">
                                    <p className="reportText has-text-primary-dark">
                                    Tu consommes au moins une de ces boissons sucrées chaque jour. Pense à réduire ta fréquence de consommation.
                                    </p>
                                </li> : null
                            }
                            {
                                (selections[7] 
                                    && calculateTrueConditions(
                                        [21, 22].includes(selections[7].subQuestions[3].choice_answer_id),
                                        [21, 22].includes(selections[7].subQuestions[5].choice_answer_id),
                                        [21, 22].includes(selections[7].subQuestions[8].choice_answer_id),
                                        [21, 22].includes(selections[7].subQuestions[14].choice_answer_id),
                                    ) >= 2) ?
                                <li key="4_2">
                                    <p className="reportText has-text-primary-dark">
                                    Tu consommes une variété de ces boissons chaque semaine. Réduis ta fréquence de consommation.
                                    </p>
                                </li> : null
                            }
                            {
                                (selections[7] 
                                    && calculateTrueConditions(
                                    [21, 22].includes(selections[7].subQuestions[3].choice_answer_id),
                                    [21, 22].includes(selections[7].subQuestions[5].choice_answer_id),
                                    [21, 22].includes(selections[7].subQuestions[8].choice_answer_id),
                                    [21, 22].includes(selections[7].subQuestions[14].choice_answer_id)) === 1) ?
                                <li key="4_3">
                                    <p className="reportText has-text-primary-dark">
                                    Tu consommes au moins une de ces boissons sucrées chaque semaine. Assure-toi que ta consommation reste qu’à l’occasion. 
                                    </p>
                                </li> : null
                            }


                            {/*{*/}
                            {/*    (selections[7] */}
                            {/*        && calculateTrueConditions([23].includes(selections[7].subQuestions[3].choice_answer_id),*/}
                            {/*        [23].includes(selections[7].subQuestions[5].choice_answer_id),*/}
                            {/*        [23].includes(selections[7].subQuestions[8].choice_answer_id),*/}
                            {/*        [23].includes(selections[7].subQuestions[14].choice_answer_id)) === 1) ?*/}
                            {/*    <li key="4_4">*/}
                            {/*        <p className="reportText has-text-primary-dark">*/}
                            {/*        Tu consommes au moins une de ces boissons sucrées chaque jour. Pense à réduire ta fréquence de consommation.*/}
                            {/*        </p>*/}
                            {/*    </li> : null*/}
                            {/*}*/}
                            {/*{*/}
                            {/*    (selections[7] */}
                            {/*        && calculateTrueConditions([24].includes(selections[7].subQuestions[3].choice_answer_id),*/}
                            {/*        [24].includes(selections[7].subQuestions[5].choice_answer_id),*/}
                            {/*        [24].includes(selections[7].subQuestions[8].choice_answer_id),*/}
                            {/*        [24].includes(selections[7].subQuestions[14].choice_answer_id)) === 1) ?*/}
                            {/*    <li key="4_5">*/}
                            {/*        <p className="reportText has-text-primary-dark">*/}
                            {/*        Tu consommes au moins une de ces boissons sucrées plusieurs fois par jour. Pense à réduire ta fréquence de consommation.*/}
                            {/*        </p>*/}
                            {/*    </li> : null*/}
                            {/*}*/}

                        </ul>
                    </div>
                    <div className="content">
                        <p className="reportText has-text-primary">
                            Que penser des...
                        </p>
                        <ul>
                            {
                                (selections[7] 
                                    && [20, 21, 22, 23, 24].includes(selections[7].subQuestions[7].choice_answer_id)) ?
                                <li key="5_1">
                                    <p className="reportText has-text-primary-dark">
                                    Smoothies ? C’est une façon simple d’ajouter des fruits à ton menu : des fruits, du lait, une boisson végétale et/ou 
                                    du yogourt… le tour est joué, et ce, sans sucre ajouté ! 
                                    Attention, les smoothies du commerce sont souvent riches en sucre. 
                                    </p>
                                </li> : null
                            }
                            {
                                (selections[7] 
                                    && [20, 21, 22, 23, 24].includes(selections[7].subQuestions[9].choice_answer_id)) ?
                                <li key="5_2">
                                    <p className="reportText has-text-primary-dark">
                                    Jus de légumes ? Préfère les versions réduites en sel ou sans sel.  
                                    </p>
                                </li> : null
                            }
                            {
                                (selections[7] 
                                    && [20, 21, 22, 23, 24].includes(selections[7].subQuestions[14].choice_answer_id)) ?
                                <li key="5_3">
                                    <p className="reportText has-text-primary-dark">
                                    Eaux vitaminées sucrées ? En plus de leur longue liste de ingrédients, elles sont 
                                    souvent presqu’aussi sucrées que les boissons gazeuses 
                                    et peuvent contenir trop de vitamines ou minéraux pour toi. Évite-les.
                                    </p>
                                </li> : null
                            }
                            <li key="5_4">
                                <p className="reportText has-text-primary-dark">
                                    Boissons sportives ? Elles sont conseillées seulement lors d’une pratique sportive d’endurance de plus d’une 
                                    heure ou bien pour les personnes qui suent beaucoup lors d’une activité physique. 
                                    Sinon, choisis l’eau. Lorsque nécessaire, prépare ta propre boisson pour éviter les longues 
                                    listes d’ingrédients du commerce :
                                </p>
                            </li>
                        </ul>
                    </div>
                    <div className="centeredPicture">
                        <div className="innerPicture" style={{width: "40%"}}>
                            <img src={boissonSport} alt="Image boissons sportives" />
                        </div>
                    </div>
                    <h3 style={{marginTop: "40px"}} className="title3 has-text-info">5. Boissons diètes</h3>
                    <div className="content">
                        <p className="reportText has-text-primary">
                        Ce sont des boissons d’exception. Elles contiennent des édulcorants (sucralose, aspartame, acésulfame K, sorbitol, etc.) 
                        qui entretiennent inutilement 
                        le goût pour la saveur sucrée et qui pourraient avoir un effet néfaste sur le microbiote intestinal.
                        </p>
                        {
                            (selections[7] 
                                && [19, 20].includes(selections[7].subQuestions[13].choice_answer_id)
                                && [19, 20].includes(selections[7].subQuestions[15].choice_answer_id)
                                && [19, 20].includes(selections[7].subQuestions[18].choice_answer_id)
                                && [19, 20].includes(selections[7].subQuestions[21].choice_answer_id)) ?
                                <p className="reportText has-text-primary-dark">
                                Continue de les exclure.
                                </p> : null
                        }
                        {
                            (selections[7] 
                                && ([21, 22, 23, 24].includes(selections[7].subQuestions[13].choice_answer_id)
                                || [21, 22, 23, 24].includes(selections[7].subQuestions[15].choice_answer_id)
                                || [21, 22, 23, 24].includes(selections[7].subQuestions[18].choice_answer_id)
                                || [21, 22, 23, 24].includes(selections[7].subQuestions[21].choice_answer_id))) ?
                                <p className="reportText has-text-primary-dark">
                                Limite ta consommation de :
                                </p> : null
                        }
                        <ul>
                        {
                            (selections[7] 
                                && [21, 22, 23, 24].includes(selections[7].subQuestions[13].choice_answer_id)) ?
                                <li key="6_1">
                                <p className="reportText has-text-primary-dark">
                                eau aromatisée avec édulcorant 
                                </p></li>: null
                        }
                        {
                            (selections[7] 
                                && [21, 22, 23, 24].includes(selections[7].subQuestions[15].choice_answer_id)) ?
                                <li key="6_2">
                                <p className="reportText has-text-primary-dark">
                                eau vitaminée sans sucre
                                </p></li>: null
                        }
                        {
                            (selections[7] 
                                && [21, 22, 23, 24].includes(selections[7].subQuestions[18].choice_answer_id)) ?
                                <li key="6_3">
                                <p className="reportText has-text-primary-dark">
                                boissons diètes, sans sucre ou faibles en calories
                                </p></li>: null
                        }
                        {
                            (selections[7] 
                                && [21, 22, 23, 24].includes(selections[7].subQuestions[21].choice_answer_id)) ?
                                <li key="6_4">
                                <p className="reportText has-text-primary-dark">
                                boissons caféinées sans sucre ou faibles en calories
                                </p></li>: null
                        }
                        </ul>
                    </div>
                    <h3 style={{marginTop: "40px"}} className="title3 has-text-info">6. Boissons sucrées sans nutriments</h3>
                    <div className="content">
                        <p className="reportText has-text-primary">
                        Ce sont des boissons d’exception. Elles n’apportent aucun nutriment et leur contenu 
                        élevé en sucres ajoutés augmente les risques de carie 
                        dentaire, diabète de type 2, maladies cardiovasculaires et stéatose hépatique.
                        </p>
                        {
                            (selections[7]
                                && ([19].includes(selections[7].subQuestions[17].choice_answer_id)
                                && [19].includes(selections[7].subQuestions[20].choice_answer_id))) ?
                                <p className="reportText has-text-primary-dark">
                                Continue de les éviter.
                                </p> : null
                        }
                        {
                            (selections[7]
                                && ( [20].includes(selections[7].subQuestions[17].choice_answer_id)
                                     || [20].includes(selections[7].subQuestions[20].choice_answer_id)
                                )
                            ) ?
                                <p className="reportText has-text-primary-dark">
                                Continue de les éviter.
                                </p> : null
                        }
                        {
                            (selections[7]
                                &&
                                (
                                    [21, 22, 23, 24].includes(selections[7].subQuestions[17].choice_answer_id)
                                 || [21, 22, 23, 24].includes(selections[7].subQuestions[20].choice_answer_id)
                                )
                                && ![20].includes(selections[7].subQuestions[17].choice_answer_id)
                                && ![20].includes(selections[7].subQuestions[20].choice_answer_id)
                            ) ?
                                <p className="reportText has-text-primary-dark">
                                Diminue ta consommation de :
                                </p> : null
                        }
                        <ul>
                        {
                            (selections[7] 
                                && [21, 22, 23, 24].includes(selections[7].subQuestions[17].choice_answer_id)) ?
                                <li key="7_1">
                                <p className="reportText has-text-primary-dark">
                                boissons sucrées à saveur de fruits, gazeuses, barbotines, chocolat chaud
                                </p></li>: null
                        }
                        {
                            (selections[7] 
                                && [21, 22, 23, 24].includes(selections[7].subQuestions[20].choice_answer_id)) ?
                                <li key="7_2">
                                <p className="reportText has-text-primary-dark">
                                boissons caféinées sucrées comme les boissons énergisantes et les cafés aromatisés
                                </p></li>: null
                        }
                        </ul>
                    </div>
                    <div className="centeredPicture">
                        <div className="innerPicture" style={{width: "40%"}}>
                            <img src={bulles} alt="Image boissons bulles" />
                        </div>
                    </div>
                    <div style={{ padding: "10px 10px 10px 10px"}} className="has-background-info-light">
                        <h3 className="titlePourAllerPLusLoin has-text-info">Pour aller plus loin</h3>
                        <div className="content has-background-info-light">
                            <p className="textPourAllerPLusLoin">
                                <a href="https://nutritionnisteurbain.ca/infographiques/9-boissons-shydrater-nutritionniste/" target="_blank">
                                    <span style={{marginRight: "8px"}} className="icon">
                                        <i className="fas fa-hand-point-right"></i>
                                    </span>
                                    <i>Infographie 9 Boissons pour s’hydrater comme un nutritionniste de Bernard Lavallée</i>
                                </a>
                                <br />
                                <a href="https://www.hsph.harvard.edu/nutritionsource/healthy-drinks-full-story/" target="_blank">
                                <span style={{marginRight: "8px"}} className="icon">
                                    <i className="fas fa-hand-point-right"></i>
                                </span>
                                    <i>Healthy Beverage Guidelines, Harvard – T.H. Chan</i>
                                </a>
                            </p>
                        </div>
                    </div>

                    <h3 style={{marginTop: "40px"}} className="title3 has-text-info">L’étiquetage nutritionnel</h3>

                    <div className="content">
                        {
                            (selections[10]
                                && ([28, 29].includes(selections[10].subQuestions[2].choice_answer_id)
                                || [28, 29].includes(selections[10].subQuestions[3].choice_answer_id))) ?
                                <p className="reportText has-text-primary-dark">
                                    Tu as bien raison de le consulter, car cela te permet de faire de meilleurs choix et 
                                    de reconnaître les aliments ultra-transformés.
                                </p> : (selections[10]
                                && ([25, 26, 27].includes(selections[10].subQuestions[2].choice_answer_id)
                                || [25, 26, 27].includes(selections[10].subQuestions[3].choice_answer_id))) ?
                                <p className="reportText has-text-primary-dark">
                                    Prend l’habitude de le consulter régulièrement afin de faire de meilleurs choix et 
                                    reconnaître les aliments ultra-transformés. 
                                </p> : (selections[10]
                                && ([30].includes(selections[10].subQuestions[2].choice_answer_id)
                                || [30].includes(selections[10].subQuestions[3].choice_answer_id))) ?
                                <div>
                                    <p className="reportText has-text-primary-dark">
                                    Consulte-le afin de faire de meilleurs choix et reconnaître les aliments ultra-transformés.
                                    </p>
                                </div>: null
                        }

                        <div className="centeredPicture">
                            <div className="innerPicture" style={{width: "75%"}}>
                                <img src={EtiquetageNutritionnel101} alt="Étiquetage nutritionnel" />
                            </div>
                        </div>

                    </div>

                    <div style={{ padding: "10px 10px 10px 10px"}} className="has-background-info-light">
                        <h3 className="titlePourAllerPLusLoin has-text-info">Pour aller plus loin</h3>
                        <div className="content has-background-info-light">
                            <p className="textPourAllerPLusLoin">
                                <a href="https://www.canada.ca/fr/sante-canada/services/comprendre-etiquetage-aliments.html" target="_blank">
                                    <span style={{marginRight: "8px"}} className="icon">
                                        <i className="fas fa-hand-point-right"></i>
                                    </span>
                                    <i>Comprendre l’étiquetage nutritionnel</i>
                                </a>
                                <br />
                                <a>
                                <span style={{marginRight: "8px"}} className="icon">
                                    <i className="fas fa-hand-point-right"></i>
                                </span>
                                    <i>Desgroseilliers, J. (2020). La jungle alimentaire – Comment s’y retrouver. Montréal : Les éditions La Presse.</i>
                                </a>
                                <br />
                                <a href="https://fr.openfoodfacts.org/nova" target="_blank">
                                <span style={{marginRight: "8px"}} className="icon">
                                    <i className="fas fa-hand-point-right"></i>
                                </span>
                                    <i>Classification NOVA</i>
                                </a>
                            </p>
                        </div>
                    </div>
                    {
                        (selections[8]
                            && ( fieldIncludedInSelection(selections[8],100)
                            || fieldIncludedInSelection(selections[8],101) ) ) ?
                            <h3 className="title3 has-text-info"> Régime alimentaire</h3>
                            : null
                    }
                    {
                        (selections[8]
                            && fieldIncludedInSelection(selections[8],100) ) ?
                            <div className="content">
                                <p className="reportText has-text-primary-dark">
                                Quelques vitamines et minéraux sont à surveiller dans une alimentation végétarienne selon 
                                les aliments d’origine animale que tu consommes : <b>vitamine B12, vitamine D, calcium, zinc, fer, iode, oméga-3</b>.
                                <br /><br />
                                Cela ne veut pas dire que les omnivores sont à l’abri des carences! Les végétaux sont essentiels 
                                pour plusieurs éléments nutritifs, dont les fibres, le potassium et la vitamine C.  
                                </p>
                            </div>
                            : null
                    }
                    {
                        (selections[8]
                            && fieldIncludedInSelection(selections[8],101) ) ?
                            <div className="content">
                                <p className="reportText has-text-primary-dark">
                                Quelques vitamines et minéraux sont à surveiller dans une alimentation végétalienne : 
                                <b>vitamine B12, vitamine D, calcium, zinc, fer, iode, oméga-3</b>.
                                <br /><br />
                                Cela ne veut pas dire que les omnivores sont à l’abri des carences! Les végétaux sont essentiels 
                                pour plusieurs éléments nutritifs, dont les fibres, le potassium et la vitamine C. 
                                </p>
                            </div>
                            : null
                    }
                    {
                        (selections[8]
                            && ( fieldIncludedInSelection(selections[8],100)
                            || fieldIncludedInSelection(selections[8],101) )) ?
                        <div style={{ padding: "10px 10px 10px 10px"}} className="has-background-info-light">
                            <h3 className="titlePourAllerPLusLoin has-text-info">Pour aller plus loin</h3>
                            <div className="content has-background-info-light">
                                <p className="textPourAllerPLusLoin">
                                    <a href="http://cscp.umontreal.ca/nutrition/documents/Viens_manger_vegetarisme.pdf" target="_blank">
                                        <span style={{marginRight: "8px"}} className="icon">
                                            <i className="fas fa-hand-point-right"></i>
                                        </span>
                                        <i>Viens manger! Le végétarisme en toute simplicité.</i>
                                    </a>
                                    <br />
                                    <a href="https://www.savoirmanger.ca/guide-alimentaire-vegetarien-et-vegetalien-p2049.html" target="_blank">
                                    <span style={{marginRight: "8px"}} className="icon">
                                        <i className="fas fa-hand-point-right"></i>
                                    </span>
                                        <i>Les guides alimentaires végétarien et végétalien</i>
                                    </a>
                                    <br />
                                    <a href="https://www.soscuisine.com/blog/3-cles-de-succes-pour-les-vegetaliens-debutants/?lang=fr" target="_blank">
                                    <span style={{marginRight: "8px"}} className="icon">
                                        <i className="fas fa-hand-point-right"></i>
                                    </span>
                                        <i>3 clés de succès pour les végétaliens débutants</i>
                                    </a>
                                </p>
                            </div>
                        </div>
                            : null
                    }
                    <div className="titleContainer">
                        <h1 style={{marginTop: "50px", marginBottom: "20px"}} className="reportTitle has-text-success-dark">
                            Section 2: Tes habitudes alimentaires
                        </h1>
                    </div>
                    <div className="content">
                        <p className="reportText has-text-success">
                        Pour avoir un meilleur portrait de la saine alimentation, il ne suffit pas de seulement 
                        tenir compte des aliments consommés, mais aussi des habitudes entourant l’alimentation.
                        </p>
                    </div>
                    <div className="centeredPicture">
                        <div className="innerPicture" style={{width: "65%"}}>
                            <img src={section2} alt="Image section 2" />
                        </div>
                    </div>
                    <h2 style={{marginTop: "35px"}} className="reportSubtitle">QUAND : Moment où tu manges</h2>
                    <div className="content">
                        <p className="reportText has-text-success">
                        Un horaire de repas régulier te permet d’avoir l’occasion de manger trois repas par jour. 
                        Cela permet un meilleur contrôle de l’appétit durant la journée et rend plus stables l’énergie et la concentration. 
                        Tout en respectant ta faim, il faut s’offrir l’occasion de manger en évitant de sauter des repas.
                        </p>
                        {
                        (selections[11]
                            && [35].includes(selections[11].subQuestions[1].choice_answer_id)
                            && [35].includes(selections[11].subQuestions[2].choice_answer_id)
                            && [35].includes(selections[11].subQuestions[3].choice_answer_id)) ?
                            <p className="reportText has-text-success-dark">
                                Continue d’avoir un horaire de repas régulier.
                            </p> : null
                        }
                    </div>
                    <h3 className="title3 has-text-info">Le déjeuner</h3>
                    <div className="content">
                        {
                            (selections[11]
                                && [35].includes(selections[11].subQuestions[1].choice_answer_id)
                                && [35].includes(selections[11].subQuestions[2].choice_answer_id)
                                && [35].includes(selections[11].subQuestions[3].choice_answer_id)) ?
                            <p className="reportText has-text-success-dark">
                                Tous les aliments donnent de l’énergie, mais certains sont plus nutritifs et soutiennent 
                                jusqu’à la collation de l’avant-midi. Choisis des déjeuners soutenant comme un bol de céréales peu sucrées 
                                et riches en fibres, une rôtie au beurre d’arachide, 
                                du gruau ou un œuf plutôt que des céréales à déjeuner bonbons ou des rôties chocolatées.
                            </p> : null
                        }
                        {
                            (selections[11]
                                && [36].includes(selections[11].subQuestions[1].choice_answer_id)) ?
                            <p className="reportText has-text-success-dark">
                                Assure-toi de déjeuner afin de refaire le plein après le « jeûne » de la nuit. 
                                Se mettre un petit quelque chose sous la dent est suffisant si la faim n’est pas au rendez-vous.
                            </p> : null
                        }
                        {
                            (selections[11]
                                && [35].includes(selections[11].subQuestions[1].choice_answer_id)
                                && ([31, 32, 33, 34, 36].includes(selections[11].subQuestions[2].choice_answer_id)
                                || [31, 32, 33, 34, 36].includes(selections[11].subQuestions[3].choice_answer_id))) ?
                            <p className="reportText has-text-success-dark">
                                Continue de déjeuner tous les jours pour refaire le plein après le « jeûne » de la nuit.
                            </p> : null
                        }
                        {
                            (selections[11]
                                && [35, 36].includes(selections[11].subQuestions[1].choice_answer_id)
                                && ([31, 32, 33, 34, 36].includes(selections[11].subQuestions[2].choice_answer_id)
                                || [31, 32, 33, 34, 36].includes(selections[11].subQuestions[3].choice_answer_id))) ?
                            <p className="reportText has-text-success-dark">
                                Tous les aliments donnent de l’énergie, mais certains sont plus nutritifs et soutiennent jusqu’à 
                                la collation de l’avant-midi. Choisis des déjeuners soutenant comme un bol de céréales peu sucrées et riches en 
                                fibres, une rôtie au beurre d’arachide, 
                                du gruau ou un œuf plutôt que des céréales à déjeuner bonbons ou des rôties chocolatées.
                            </p> : null
                        }
                        {
                            (selections[11]
                                && [31, 32, 33, 34].includes(selections[11].subQuestions[1].choice_answer_id)) ?
                            <p className="reportText has-text-success-dark">
                                Essaie de déjeuner chaque matin. Ce repas très important permet de refaire le plein après le « jeûne » de la nuit. 
                                Se mettre un petit quelque chose sous la dent est suffisant si la faim n’est pas au rendez-vous.
                            </p> : null
                        }
                        {
                            (selections[11]
                                && [31, 32, 33, 34].includes(selections[11].subQuestions[1].choice_answer_id)) ?
                            <p className="reportText has-text-success-dark">
                                Tous les aliments donnent de l’énergie, mais certains sont plus nutritifs et soutiennent jusqu’à 
                                la collation de l’avant-midi. Choisis des déjeuners soutenant comme un bol de céréales peu sucrées 
                                et riches en fibres, une rôtie au beurre d’arachide, 
                                du gruau ou un œuf plutôt que des céréales à déjeuner bonbons ou des rôties chocolatées. 
                            </p> : null
                        }
                        <p className="reportText has-text-success-dark">
                        Es-tu du type matin pressé, pas faim en me levant ou long trajet à venir?
                        </p>
                    </div>
                    <div className="centeredPicture">
                        <div className="innerPicture" style={{width: "75%"}}>
                            <img src={typesMatin} alt="Image types de matin" />
                        </div>
                    </div>

                    <h3 className="title3 has-text-info">Le dîner et le souper</h3>

                    <div className="content">
                        {
                            (selections[11]
                                && [35].includes(selections[11].subQuestions[1].choice_answer_id)
                                && [35].includes(selections[11].subQuestions[2].choice_answer_id)
                                && [35].includes(selections[11].subQuestions[3].choice_answer_id)) ?
                                <p className="reportText has-text-success-dark">
                                    Opte pour des repas qui respectent les recommandations du Guide alimentaire canadien. 
                                    Consulte la première partie du rapport pour t’aider.
                                </p> : null
                        }
                        {
                            (selections[11]
                                && [31, 32, 33, 34, 36].includes(selections[11].subQuestions[1].choice_answer_id)
                                && [35].includes(selections[11].subQuestions[2].choice_answer_id)
                                && [35].includes(selections[11].subQuestions[3].choice_answer_id)) ?
                                <p className="reportText has-text-success-dark">
                                    Continue de dîner et souper tous les jours. 
                                </p> : null
                        }
                        {
                            (selections[11]
                                && ([36].includes(selections[11].subQuestions[2].choice_answer_id)
                                || [36].includes(selections[11].subQuestions[3].choice_answer_id))) ?
                                <p className="reportText has-text-success-dark">
                                    Assure-toi de dîner et souper chaque jour.
                                </p> : null
                        }
                        {
                            (selections[11] && selections[12]
                                && ([31, 32, 33, 34].includes(selections[11].subQuestions[2].choice_answer_id)
                                || [31, 32, 33, 34].includes(selections[11].subQuestions[3].choice_answer_id) )
                                && (fieldIncludedInSelection(selections[12],37) ||
                                    fieldIncludedInSelection(selections[12],39) ||
                                    fieldIncludedInSelection(selections[12],40) ||
                                    fieldIncludedInSelection(selections[12],41) ||
                                    fieldIncludedInSelection(selections[12],43)
                                    )) ?
                                <div className="reportText has-text-success-dark">
                                <p className="reportText has-text-success-dark">
                                    Il arrive parfois de :
                                </p>
                                    <ul>
                                    {fieldIncludedInSelection(selections[12],37) ? <li className="reportText has-text-success-dark"> Ne pas avoir faim </li> : null}
                                    {fieldIncludedInSelection(selections[12],39) ? <li className="reportText has-text-success-dark"> Ne pas se sentir bien</li> : null }
                                    {fieldIncludedInSelection(selections[12],40) ? <li className="reportText has-text-success-dark"> Se lever trop tard</li> : null }
                                    {fieldIncludedInSelection(selections[12],41) ? <li className="reportText has-text-success-dark"> Bruncher</li> : null }
                                    {fieldIncludedInSelection(selections[12],43) ? <li className="reportText has-text-success-dark"> Avoir une activité sportive</li> : null }
                                    </ul>
                                    ... mais assure-toi de combler tes besoins dans la journée. Pour ce faire, opte pour
                                    un horaire régulier de repas et planifie des repas qui respectent les recommandations
                                    du Guide alimentaire canadien. Consulte la première partie du rapport pour t’aider.
                                </div>
                                :
                                (selections[11] && selections[12]
                                && ([31, 32, 33, 34].includes(selections[11].subQuestions[2].choice_answer_id)
                                || [31, 32, 33, 34].includes(selections[11].subQuestions[3].choice_answer_id))) ?
                                <p className="reportText has-text-success-dark">
                                    Ces deux repas sont tout aussi important. Opte pour des repas qui respectent les 
                                    recommandations du Guide alimentaire canadien. Consulte la première partie du rapport pour t’aider.
                                </p> : null
                        }

                        <ul>

                            {
                                (selections[12] && fieldIncludedInSelection(selections[12],38) )?
                                <li key="8_1">
                                    <p className="reportText has-text-success-dark">
                                    <b>Pas le temps ?</b> Il n’en revient qu’à toi d’accorder une place importante à l’alimentation dans ton horaire.
                                    Les légumes et fruits congelés ainsi que les conserves de légumineuses et de légumes sont des moyens rapides
                                    d’ajouter des aliments sains à tes repas. Prépare tes repas et des crudités à
                                    l’avance. Les salades repas sont aussi des options rapides, saines et savoureuses.
                                    </p>
                                </li> : null
                            }

                            {
                                (selections[12] && fieldIncludedInSelection(selections[12],42) ) ?
                                <li key="8_2">
                                    <p className="reportText has-text-success-dark">
                                    Tu sembles vouloir <b>maigrir ou perdre du poids</b> en sautant des repas. Cette pratique peut avoir des
                                    conséquences négatives sur ta santé physique et mentale. Voir la section La raison pour
                                    laquelle tu manges (pourquoi) et informe-toi davantage sur l’approche de l’alimentation intuitive.
                                    </p>
                                </li> : null
                            }

                            {
                                (selections[12] && fieldIncludedInSelection(selections[12],44) ) ?
                                <li key="8_3">
                                    <p className="reportText has-text-success-dark">
                                    <b>Rien à manger ?</b> L’organisation et la planification des repas pour la semaine sont la clé
                                    pour s’assurer d’avoir de quoi se mettre sous la dent n’importe quand : élaborer un menu,
                                    prévoir une plage horaire pour l’épicerie et la cuisine et s’assurer d’avoir des aliments
                                    sains à disposition (légumineuses, légumes ou poisson en conserve, légumes et fruits congelés,
                                    boisson de soya en
                                    tétra pack, noix et graines, fruits séchés, etc.) sont des trucs simples pour y arriver.
                                    </p>
                                </li> : null
                            }

                        </ul>

                    </div>

                    <h3 className="title3 has-text-info">Les collations</h3>

                    <div className="content">
                        <p className="reportText has-text-success">
                        Elles permettent d’éviter les baisses d’énergie dans la journée, le grignotage ou de manger trop aux repas suivants. 
                        Elles sont une alliée pour te soutenir jusqu’au prochain repas, surtout si elles sont riches en fibres et en 
                        protéines : crudités, fruits, produits céréaliers à grains entiers… accompagnés d’une source de protéines 
                        (houmous, yogourt, fromage, beurre d’arachides ou de noix, etc.)
                        </p>
                        {
                            (selections[11]
                                && ([31, 32, 33, 34, 36].includes(selections[11].subQuestions[4].choice_answer_id)
                                || [31, 32, 33, 34, 36].includes(selections[11].subQuestions[5].choice_answer_id)
                                || [31, 32, 33, 34, 36].includes(selections[11].subQuestions[6].choice_answer_id))) ?
                                <p className="reportText has-text-success-dark">
                                    Pense à les ajouter quotidiennement dans ton horaire d’alimentation, si tu les juges nécessaires. 
                                    Par exemple, prévois à l’avance de couper des légumes pour des crudités, congèle une recette de muffin maison, garde un 
                                    contenant de houmous au réfrigérateur afin d’avoir des collations saines à porter de la main.
                                </p> : selections[11] ?
                                <p className="reportText has-text-success-dark">
                                Assure-toi de faire des choix sains pour tes collations en t’inspirant de la première partie de ce rapport. 
                                </p> : null
                        }
                    </div>

                    <h2 style={{marginTop: "35px"}} className="reportSubtitle">OÙ : Endroit où tu manges</h2>

                    <h3 className="title3 has-text-info">La restauration et les mets commerciaux</h3>
                    <div className="content">
                        {
                            hasRestoCondition() ?
                                <p className="reportText has-text-success-dark">
                                    Cuisiner « maison » permet de mieux contrôler le sel, le gras, le sucre et d’éviter les additifs et les colorants, 
                                    continue donc de limiter ta consommation de mets commerciaux ou de restauration. 
                                </p> : null
                        }
                        <ul>
                            {
                                (selections[13] && !hasRestoCondition()
                                    && [47, 48, 49, 50].includes(selections[13].subQuestions[1].choice_answer_id)) ?
                                    <li key="9_1">
                                        <p className="reportText has-text-success-dark">
                                        Les mets commerciaux congelés, prêts-à-manger, en conserve ou faciles à préparer
                                        </p>
                                    </li> : null
                            }
                            {
                                (selections[13] && !hasRestoCondition()
                                    && ([47, 48, 49, 50].includes(selections[13].subQuestions[2].choice_answer_id)
                                        || [47, 48, 49, 50].includes(selections[13].subQuestions[3].choice_answer_id))) ?
                                    <li key="9_2">
                                        <p className="reportText has-text-success-dark">
                                        Les aliments venant de restaurants (à restauration rapide ou autres)
                                        </p>
                                    </li> : null
                            }
                            {
                                (selections[13] && !hasRestoCondition()
                                    && [47, 48, 49, 50].includes(selections[13].subQuestions[4].choice_answer_id)) ?
                                    <li key="9_3">
                                        <p className="reportText has-text-success-dark">
                                        Les aliments des casse-croûte ou des endroits publics
                                        </p>
                                    </li> : null
                            }
                        </ul>
                        {
                            (selections[13] && !hasRestoCondition()) ?
                            <p className="reportText has-text-success-dark">
                                …sont souvent plus riches en sel, en mauvais gras et en sucre en plus de contenir plus d’additifs et de colorants. 
                                Ils permettent de dépanner, mais l’option d’un repas cuisiné maison reste optimale. Essaie d’en limiter ta consommation.  
                            </p> : null
                        }
                        {
                            (selections[13] && !hasRestoCondition()
                                && [47, 48, 49].includes(selections[13].subQuestions[3].choice_answer_id)) ?
                                <p className="reportText has-text-success-dark">
                                Au restaurant, opte pour un plat qui s’approche de l’assiette du Guide alimentaire canadien 
                                en commandant, par exemple, des légumes en à-côté. De plus, 
                                les portions sont souvent plus grosses qu’à la maison alors n’hésite pas à rapporter 
                                une partie de ton plat pour un prochain repas.<br />
                                <br />
                                Réfléchis à quel (s) moment (s) tu manges le plus souvent ce genre de mets afin d’adapter 
                                les stratégies de planification en conséquence!
                                <br />
                                → Un truc : cuisine tes recettes en double et congèle les surplus, une option saine pour te dépanner!
                                </p> : null
                        }
                        {
                            (selections[13] && !hasRestoCondition()
                                && [51].includes(selections[13].subQuestions[1].choice_answer_id)
                                && [51].includes(selections[13].subQuestions[2].choice_answer_id)
                                && [51].includes(selections[13].subQuestions[3].choice_answer_id)
                                && [51].includes(selections[13].subQuestions[4].choice_answer_id)) ?
                                <p className="reportText has-text-success-dark">
                                Assure-toi de limiter ta consommation de mets commerciaux et de restauration, 
                                puisque les aliments que l’on y mange 
                                sont souvent plus riches en sel, en mauvais gras, en sucre en plus de contenir plus d’additifs et de colorants.
                                <br /><br />
                                Réfléchis à quel (s) moment (s) tu manges le plus souvent ces mets afin d’adapter les stratégies
                                de planification en conséquence!
                                <br />
                                → Un truc : cuisine tes recettes en double et congèle les surplus, une option saine pour se dépanner!
                                </p> : null
                        }
                    </div>
                    <div style={{ padding: "10px 10px 10px 10px"}} className="has-background-info-light">
                        <h3 className="titlePourAllerPLusLoin has-text-info">Pour aller plus loin</h3>
                        <div className="content has-background-info-light">
                            <p className="textPourAllerPLusLoin">
                                <a href="https://guide-alimentaire.canada.ca/fr/recommandations-en-matiere-dalimentation-saine/limitez-consommation-aliments-hautement-transformes/faire-choix-menus-sante/"
                                    target="_blank">
                                    <span style={{marginRight: "8px"}} className="icon">
                                        <i className="fas fa-hand-point-right"></i>
                                    </span>
                                    <i>Comment faire de meilleurs choix au restaurant?</i>
                                </a>
                            </p>
                        </div>
                    </div>
                    <h2 style={{marginTop: "35px"}} className="reportSubtitle">QUI : Avec qui tu manges</h2>
                    <div className="content">
                        <p className="reportText has-text-success">
                        Prendre des repas en bonne compagnie permet d’échanger avec les autres, 
                        de se rapprocher et de découvrir de nouveaux aliments sains. 
                        C’est aussi une occasion de partager des traditions alimentaires et rendre la vie agréable ! 
                        </p>
                        {
                            (selections[14]
                                && [52, 53, 54].includes(selections[14].subQuestions[1].choice_answer_id)
                                && [55, 56].includes(selections[14].subQuestions[2].choice_answer_id)) ?
                                <p className="reportText has-text-success">
                                    Continue de profiter des repas avec les personnes que tu apprécies!
                                </p> : null
                        }
                        {
                            (selections[14]
                                && [55, 56].includes(selections[14].subQuestions[1].choice_answer_id)
                                && [52, 53, 54].includes(selections[14].subQuestions[2].choice_answer_id)) ?
                                <p className="reportText has-text-success">
                                    Si tu le souhaites et lorsque possible, il serait intéressant de partager certains repas avec tes amis, 
                                    ta famille, tes voisins, tes collègues de travail ou d’école afin de maximiser les repas en bonne compagnie.
                                </p> : null
                        }
                        {
                            (selections[14]
                                && (([57].includes(selections[14].subQuestions[1].choice_answer_id)
                                    || [57].includes(selections[14].subQuestions[2].choice_answer_id))
                                || ([52, 53, 54].includes(selections[14].subQuestions[1].choice_answer_id)
                                    && [52, 53, 54].includes(selections[14].subQuestions[2].choice_answer_id))
                                || ([55, 56].includes(selections[14].subQuestions[1].choice_answer_id)
                                    && [55, 56].includes(selections[14].subQuestions[2].choice_answer_id)))) ?
                                <p className="reportText has-text-success">
                                    Si tu le souhaites et lorsque possible, partage les repas avec tes amis, 
                                    famille, voisins, collègues de travail ou d’école afin de maximiser les repas en bonne compagnie. 
                                </p> : null
                        }
                    </div>
                    <h2 style={{marginTop: "35px"}} className="reportSubtitle">COMMENT : Manière dont tu manges et planifies</h2>
                    <h3 className="title3 has-text-info">Distractions aux repas</h3>
                    <div className="content">
                        {
                            (selections[15]
                                && [63].includes(selections[15].subQuestions[1].choice_answer_id)
                                && [63].includes(selections[15].subQuestions[2].choice_answer_id)
                                && [63].includes(selections[15].subQuestions[3].choice_answer_id)) ?
                                <p className="reportText has-text-success-dark">
                                    Continue de manger sans distraction (télévision, ordinateur, cellulaire, etc.). 
                                    Cela t’aide à reconnaître tes sensations de satiété et éviter de trop manger durant ton repas. 
                                    D’ailleurs, la probabilité de manger en trop grande quantité au cours des collations et repas suivants 
                                    s’en trouve réduite. Prendre le temps d’apprécier tes repas, seul(e) ou en compagnie, 
                                    est l’idéal pour rester à l’écoute de tes signaux de satiété et de réellement savourer les aliments. 
                                </p> : null
                        }
                        {
                            (selections[15]
                                && [64].includes(selections[15].subQuestions[1].choice_answer_id)
                                && [64].includes(selections[15].subQuestions[2].choice_answer_id)
                                && [64].includes(selections[15].subQuestions[3].choice_answer_id)) ?
                                <p className="reportText has-text-success-dark">
                                    Tu sembles manger peu fréquemment avec des distractions (télévision, ordinateur, cellulaire, etc.). 
                                    C’est une bonne habitude à prendre, 
                                    car cela t’aide à reconnaître les sensations de satiété et éviter de trop manger durant ton repas. 
                                    D’ailleurs, la probabilité de manger en trop grande quantité au cours des collations et repas suivants 
                                    s’en trouve réduite. Prendre le temps d’apprécier tes repas, seul(e) ou en compagnie, est l’idéal pour rester à 
                                    l’écoute de tes signaux de satiété et de réellement savourer les aliments.
                                </p> : null
                        }
                        {
                            (selections[15]
                                && [65].includes(selections[15].subQuestions[1].choice_answer_id)
                                && [65].includes(selections[15].subQuestions[2].choice_answer_id)
                                && [65].includes(selections[15].subQuestions[3].choice_answer_id)) ?
                                <p className="reportText has-text-success-dark">
                                    Tu sembles parfois manger avec des distractions (télévision, ordinateur, cellulaire, etc.). 
                                    Pourtant, manger avec des distractions ne te permet pas de reconnaître tes sensations de satiété. 
                                    D’ailleurs, la probabilité de manger en trop grande quantité au cours de ton repas, mais aussi des 
                                    repas et des collations suivants, s’en trouve augmentée. Prendre le temps d’apprécier tes repas, 
                                    seul(e) ou en compagnie, est 
                                    l’idéal pour rester à l’écoute de tes signaux de satiété et de réellement savourer les aliments.
                                </p> : null
                        }
                        {
                            (selections[15]
                                && ([66, 67].includes(selections[15].subQuestions[1].choice_answer_id)
                                || [66, 67].includes(selections[15].subQuestions[2].choice_answer_id)
                                || [66, 67].includes(selections[15].subQuestions[3].choice_answer_id))) ?
                                <p className="reportText has-text-success-dark">
                                    Attention, manger avec des distractions (télévision, ordinateur, cellulaire, etc.) ne 
                                    te permet pas de reconnaître tes sensations de satiété. D’ailleurs, la probabilité de manger 
                                    en trop grande quantité au cours de ton repas, mais aussi des repas et collations suivants, 
                                    s’en trouve augmentée. Essaie de prendre le temps d’apprécier ton repas, seul∙e ou en compagnie, 
                                    pour rester à l’écoute de tes signaux 
                                    de satiété et réellement savourer les aliments en limitant les distractions. 
                                </p> : null
                        }
                        {
                            (selections[15]
                                && !([63].includes(selections[15].subQuestions[1].choice_answer_id)
                                && [63].includes(selections[15].subQuestions[2].choice_answer_id)
                                && [63].includes(selections[15].subQuestions[3].choice_answer_id))
                                && !([64].includes(selections[15].subQuestions[1].choice_answer_id)
                                && [64].includes(selections[15].subQuestions[2].choice_answer_id)
                                && [64].includes(selections[15].subQuestions[3].choice_answer_id))
                                && !([65].includes(selections[15].subQuestions[1].choice_answer_id)
                                && [65].includes(selections[15].subQuestions[2].choice_answer_id)
                                && [65].includes(selections[15].subQuestions[3].choice_answer_id))
                                && !([66, 67].includes(selections[15].subQuestions[1].choice_answer_id)
                                || [66, 67].includes(selections[15].subQuestions[2].choice_answer_id)
                                || [66, 67].includes(selections[15].subQuestions[3].choice_answer_id))) ?
                                <p className="reportText has-text-success-dark">
                                    Assure-toi de manger sans distractions (télévision, ordinateur, cellulaire, etc.) aux repas. 
                                    Cela t’aide à reconnaître les sensations de satiété et éviter de trop manger durant ton repas. 
                                    D’ailleurs, la probabilité de manger en trop grande quantité au cours des collations et repas suivants 
                                    s’en trouve réduite. Prendre le temps d’apprécier tes repas, seul(e) ou en compagnie, est 
                                    l’idéal pour rester à l’écoute de tes signaux de satiété et de réellement savourer les aliments. 
                                </p> : null
                        }
                    </div>
                    <h3 className="title3 has-text-info">Faire l’épicerie</h3>
                    <div className="content">
                        {
                            (selections[10]
                                && [28, 29].includes(selections[10].subQuestions[1].choice_answer_id)) ?
                                <p className="reportText has-text-success-dark">
                                    Continue de faire ton épicerie puisque cela réduit la dépendance aux restaurants et aux mets préparés. 
                                    C’est un moyen très économique de se nourrir de bons choix alimentaires. <br />
                                    <br />
                                    N’oublie pas que manger, c’est voter : choisis des produits alignés avec tes valeurs 
                                    (aliments biologiques, équitables, sans emballage superflu, locaux, végétaliens, etc.).
                                </p> : (selections[10] && [25, 26, 27].includes(selections[10].subQuestions[1].choice_answer_id)) ?
                                <p className="reportText has-text-success-dark">
                                    Faire son épicerie réduit la dépendance aux restaurants et aux mets préparés. 
                                    C’est un moyen très économique de se nourrir de bons choix alimentaires.
                                    <br /> <br/>
                                    Planifie les repas de la semaine et fais-toi une liste d’épicerie.
                                    <br /> <br/>
                                    Feuillette la circulaire de différentes épiceries pour connaître les meilleures aubaines de la semaine.
                                    <br /><br />
                                    N’oublie pas que manger, c’est voter : choisis des produits alignés avec tes valeurs 
                                    (aliments biologiques, équitables, sans emballage superflu, locaux, végétaliens, etc.).
                                </p> : (selections[10] && [30].includes(selections[10].subQuestions[1].choice_answer_id)) ?
                                <p className="reportText has-text-success-dark">
                                    Assure-toi de faire ton épicerie autant que possible puisque cela réduit la dépendance aux restaurants et aux mets préparés. 
                                    C’est un moyen très économique de se nourrir de bons choix alimentaires.
                                    <br />
                                    <br />
                                    N’oublie pas que manger, c’est voter : choisis des produits alignés avec tes valeurs 
                                    (aliments biologiques, équitables, sans emballage superflu, locaux, végétaliens, etc.).
                                </p> : null
                        }
                    </div>
                    <h3 className="title3 has-text-info">Cuisiner</h3>
                    <div className="content">
                        <p className="reportText has-text-success-dark">
                        Cuisiner soi-même permet de contrôler la quantité de sel, de gras et de sucre que l’on utilise. 
                        Il est aussi plus facile de choisir des ingrédients sains et de moins dépendre des produits ultra-transformés. 
                        </p>
                        {
                            (selections[10]
                                && [28, 29].includes(selections[10].subQuestions[4].choice_answer_id)) ?
                            <p className="reportText has-text-success-dark">
                                Continue de le faire pour ta santé, ton portefeuille et ton temps!
                            </p> : null
                        }
                        {
                            (selections[10]
                                && [25, 26, 27].includes(selections[10].subQuestions[4].choice_answer_id)) ?
                            <p className="reportText has-text-success-dark">
                                Tente de le faire plus souvent pour ta santé, ton portefeuille et ton temps!
                            </p> : null
                        }
                        {
                            (selections[10]
                                && [30].includes(selections[10].subQuestions[4].choice_answer_id)) ?
                            <p className="reportText has-text-success-dark">
                                N’oublie pas de cuisiner aussi souvent que possible pour ta santé, ton portefeuille et ton temps!
                            </p> : null
                        }
                        <p className="reportText has-text-success">
                            Trucs pour gagner du temps :
                        </p>
                        <ul>
                            <li key="10_1">
                                <p className="reportText has-text-success">
                                    Utilise les restants pour faire un deuxième repas réinventé
                                </p>
                            </li>
                            <li key="10_2">
                                <p className="reportText has-text-success">
                                    Cuisine en plus grande quantité et congèle en portions individuelles
                                </p>
                            </li>
                            <li key="10_3">
                                <p className="reportText has-text-success">
                                    Prépare certains ingrédients en plus grande quantité pour les repas des prochains jours
                                </p>
                            </li>
                            <li key="10_4">
                                <p className="reportText has-text-success">
                                    Lave et coupe des légumes au retour de l’épicerie
                                </p>
                            </li>
                            <li key="10_5">
                                <p className="reportText has-text-success">
                                    Cuisine des légumes congelés ou pré-préparés
                                </p>
                            </li>
                            <li key="10_6">
                                <p className="reportText has-text-success">
                                    Utilise des appareils pour te faciliter la vie
                                </p>
                            </li>
                            <li key="10_7">
                                <p className="reportText has-text-success">
                                    Garde-toi des provisions saines sous la main
                                </p>
                            </li>
                        </ul>
                    </div>
                    <h3 className="title3 has-text-info">Expérimenter de nouvelles recettes et techniques culinaires</h3>
                    <p className="reportText has-text-success">
                        Essayer de nouvelles recettes permet de découvrir de nouvelles saveurs et, qui sait, certains talents cachés! 
                    </p>
                    <div className="content">
                    {
                        (selections[10]
                            && [28, 29].includes(selections[10].subQuestions[5].choice_answer_id)) ?
                            <p className="reportText has-text-success-dark">
                                Continue de le faire.
                            </p> : null
                    }
                    {
                        (selections[10]
                            && [25, 26, 27].includes(selections[10].subQuestions[5].choice_answer_id)) ?
                            <p className="reportText has-text-success-dark">
                                Trouve de nouvelles recettes à réaliser chaque semaine pour te motiver à cuisiner davantage 
                                et découvrir d’autres facettes de la cuisine et prévois du temps dans ton horaire pour le faire.
                            </p> : null
                    }
                    {
                        (selections[10]
                            && [30].includes(selections[10].subQuestions[5].choice_answer_id)) ?
                            <p className="reportText has-text-success-dark">
                                N’hésite pas à le faire souvent en cuisinant de nouvelles recettes et en te prévoyant 
                                du temps à ton horaire pour le faire. 
                            </p> : null
                    }
                    </div>
                    <h2 style={{marginTop: "35px"}} className="reportSubtitle">POURQUOI : Raisons pour lesquelles tu manges</h2>
                    <div className="content">
                        <p className="reportText has-text-success">
                        L’alimentation intuitive est une approche qui vise à développer une relation saine 
                        avec la nourriture et avec son corps. On vise l’écoute de ses besoins.  
                        </p>
                        {
                            (selections[16]
                                && [69].includes(selections[16].subQuestions[1].choice_answer_id)
                                && [72, 73].includes(selections[16].subQuestions[2].choice_answer_id)
                                && [69, 70, 71].includes(selections[16].subQuestions[3].choice_answer_id)
                                && [72, 73].includes(selections[16].subQuestions[4].choice_answer_id)
                                && [69].includes(selections[16].subQuestions[5].choice_answer_id)
                                && [69, 70].includes(selections[16].subQuestions[6].choice_answer_id)
                                && [69].includes(selections[16].subQuestions[7].choice_answer_id)
                                && [69, 70].includes(selections[16].subQuestions[8].choice_answer_id)
                                && [69, 70].includes(selections[16].subQuestions[9].choice_answer_id)
                                && [69, 70].includes(selections[16].subQuestions[10].choice_answer_id)
                                && [69, 70].includes(selections[16].subQuestions[11].choice_answer_id)
                                && [72, 73].includes(selections[16].subQuestions[12].choice_answer_id)
                                && [72, 73].includes(selections[16].subQuestions[13].choice_answer_id)
                                && [69, 70].includes(selections[16].subQuestions[14].choice_answer_id)
                                && [69, 70].includes(selections[16].subQuestions[15].choice_answer_id)
                                && [72, 73].includes(selections[16].subQuestions[16].choice_answer_id)) ?
                                <p className="reportText has-text-success-dark">
                                    Ta relation avec la nourriture semble très bonne. Continue de manger les aliments qui te font plaisir, 
                                    tout en respectant tes signaux de faim et de satiété. À titre informatif, voici les 10 principes de 
                                    l’alimentation intuitive tirés du site web de 
                                    Karine Gravel, nutritionniste et docteure en nutrition, qui utilise cette approche.
                                </p> :
                                <p className="reportText has-text-success-dark">
                                    Voici les 10 principes de l’alimentation intuitive tirés du site web de Karine Gravel, nutritionniste 
                                    et docteure en nutrition, qui utilise cette approche.  Les principes détaillés sont ceux pour lesquels
                                    tu pourrais entamer une réflexion afin de développer une relation plus saine avec l’alimentation.
                            </p>
                        }
                        <p className="reportText has-text-success">
                            <b>Principe 1 : Rejeter la mentalité des diètes amaigrissantes qui crée de faux espoirs</b>
                        </p>
                        {
                            (selections[16]
                                && ([70, 71, 72, 73].includes(selections[16].subQuestions[1].choice_answer_id)
                                || (selections[12] && fieldIncludedInSelection(selections[12],42)  ))) ?
                                <p className="reportText has-text-success-dark">
                                    95 % des personnes qui suivent une diète reprennent le poids perdu, et même davantage, 
                                    cinq ans après avoir entrepris une diète amaigrissante. Plutôt que de suivre des règles ou 
                                    une diète qui te dictent quoi, 
                                    quand et/ou quelle quantité manger, apprend à te fier aux signaux que ton corps t’envoie. 
                                </p> : 
                                (selections[16]
                                    && selections[16].subQuestions[1].choice_answer_id === 74) ?
                                <p className="reportText has-text-success-dark">
                                    95 % des personnes qui suivent une diète reprennent le poids perdu, et même davantage, 
                                    cinq ans après avoir entrepris une diète amaigrissante. Assure-toi de ne pas suivre des règles ou une diète qui te 
                                    dicte quoi, quand et/ou quelle quantité manger. Apprends plutôt à te fier aux signaux que ton corps t’envoie.
                                </p>
                                    :
                                    null
                        }
                        <br />
                        <p className="reportText has-text-success">
                            <b>Principe 2 : Se permettre de manger tous les aliments souhaités</b>
                        </p>
                        {
                            (selections[16]
                                && ([69, 70, 71].includes(selections[16].subQuestions[4].choice_answer_id)
                                || [70, 71, 72, 73].includes(selections[16].subQuestions[5].choice_answer_id)
                                || [71, 72, 73].includes(selections[16].subQuestions[6].choice_answer_id))) ?
                                <p className="reportText has-text-success-dark">
                                    As-tu remarqué qu’en faisant de certains aliments des interdits, ils deviennent d’autant plus attirants? 
                                    Déguste les aliments dont tu as envie quand tu en as envie et laisse aller la culpabilité. 
                                </p>
                                :
                                null
                        }
                        {
                            (selections[16]
                                && selections[16].subQuestions[4].choice_answer_id === 74
                                && selections[16].subQuestions[5].choice_answer_id === 74
                                && selections[16].subQuestions[6].choice_answer_id === 74) ?
                                <p className="reportText has-text-success-dark">
                                    As-tu remarqué qu’en faisant de certains aliments des interdits, ils deviennent d’autant plus attirants? 
                                    Assure-toi de déguster les aliments dont tu as envie quand tu en as envie et laisser aller la culpabilité.
                                </p>
                                :
                                null
                        }
                        <br />
                        <p className="reportText has-text-success">
                            <b>Principe 3 : Cesser de catégoriser les aliments comme étant bon ou mauvais</b>
                        </p>
                        {
                            (selections[16]
                                && [70, 71, 72, 73].includes(selections[16].subQuestions[7].choice_answer_id)) ?
                                <p className="reportText has-text-success-dark">
                                    Tout aliment peut faire partie d’une saine alimentation. 
                                    Mange ceux de faible valeur nutritive occasionnellement et en petite quantité.
                                </p>
                                :
                                null
                        }
                        {
                            (selections[16]
                                && selections[16].subQuestions[7].choice_answer_id === 74) ?
                                <p className="reportText has-text-success-dark">
                                    Tout aliment peut faire partie d’une saine alimentation. 
                                    Assure-toi de manger ceux de faible valeur nutritive occasionnellement et en petite quantité.
                                </p>
                                :
                                null
                        }
                        <br />
                        <p className="reportText has-text-success">
                            <b>Principe 4 : Honorer sa faim lorsqu’elle est modérée</b>
                        </p>
                        {
                            (selections[16]
                                && ([69, 70, 71].includes(selections[16].subQuestions[2].choice_answer_id)
                                || [72, 73].includes(selections[16].subQuestions[3].choice_answer_id))) ?
                                <p className="reportText has-text-success-dark">
                                    Mange lorsque tu ressens une bonne ou petite faim pour éviter les sensations d’être affamé∙e ou d’inconfort. 
                                    ie-toi aux signaux de faim de ton corps : estomac qui gargouille, 
                                    sensation de creux dans l’estomac, légère baisse d’énergie.
                                </p>
                                :
                                null
                        }
                        {
                            (selections[16]
                                && selections[16].subQuestions[2].choice_answer_id === 74
                                && selections[16].subQuestions[3].choice_answer_id === 74) ?
                                <p className="reportText has-text-success-dark">
                                    Assure-toi de manger lorsque tu ressens une bonne ou petite faim pour éviter 
                                    les sensations d’être affamé∙e ou d’inconfort. 
                                    Fie-toi aux signaux de faim de ton corps : estomac qui gargouille, sensation de creux 
                                    dans l’estomac, légère baisse d’énergie.
                                </p>
                                :
                                null
                        }
                    </div>
                    <div className="centeredPicture">
                        <div className="innerPicture" style={{width: "65%"}}>
                            <img src={echelleFaim} alt="Image échelle de la faim" />
                        </div>
                    </div>
                    <div className="content">
                        <p className="reportText has-text-success">
                            <b>Principe 5 : Considérer sa sensation de satiété pour savoir quand cesser de manger</b>
                        </p>
                        {
                            (selections[16]
                                && ([71, 72, 73].includes(selections[16].subQuestions[8].choice_answer_id)
                                || [71, 72, 73].includes(selections[16].subQuestions[9].choice_answer_id)
                                || [71, 72, 73].includes(selections[16].subQuestions[10].choice_answer_id)
                                || [71, 72, 73].includes(selections[16].subQuestions[11].choice_answer_id)
                                || [69, 70, 71].includes(selections[16].subQuestions[12].choice_answer_id))) ?
                                <p className="reportText has-text-success-dark">
                                    Prends des pauses en mangeant pour te poser la question : ai-je encore vraiment faim? 
                                    Vise une satisfaction, une sensation agréable d’être rassasié∙e afin d’éviter la sensation d’être 
                                    trop plein(e) ou gavé(e) (lourdeur, tension dans l’estomac). 
                                    Les aliments qui ont moins bon goût seraient un autre signe de satiété.
                                </p>
                                :
                                null
                        }
                        {
                            (selections[16]
                                && selections[16].subQuestions[8].choice_answer_id === 74
                                && selections[16].subQuestions[9].choice_answer_id === 74
                                && selections[16].subQuestions[10].choice_answer_id === 74
                                && selections[16].subQuestions[11].choice_answer_id === 74
                                && selections[16].subQuestions[12].choice_answer_id === 74) ?
                                <p className="reportText has-text-success-dark">
                                    Assure-toi de prendre des pauses en mangeant pour te poser la question : ai-je encore vraiment faim? 
                                    Vise une satisfaction, une sensation agréable d’être rassasié(e) afin d’éviter la 
                                    sensation d’être trop plein∙e ou gavé(e) (lourdeur, tension dans l’estomac).
                                    Les aliments qui ont moins bon goût seraient un autre signe de satiété. 
                                </p>
                                :
                                null
                        }
                        <br />
                        <p className="reportText has-text-success">
                            <b>Principe 6 : Découvrir la satisfaction et le plaisir de manger </b>
                        </p>
                        {
                            (selections[16]
                                && [69, 70, 71].includes(selections[16].subQuestions[13].choice_answer_id)) ?
                                <p className="reportText has-text-success-dark">
                                    Puisque l’acte de manger revient plusieurs fois par jour, 
                                    apprend à apprécier ces moments. Prends le temps d’apprécier 
                                    les couleurs, les odeurs et les textures des aliments. 
                                </p> : null
                        }
                        {
                            (selections[16]
                                && selections[16].subQuestions[13].choice_answer_id === 74) ?
                                <p className="reportText has-text-success-dark">
                                   Puisque l’acte de manger revient plusieurs fois par jour, assure-toi d’apprécier ces moments. 
                                   Prends le temps d’apprécier les couleurs, les odeurs et les textures des aliments. 
                                </p>
                                :
                                null
                        }
                        <br />
                        <p className="reportText has-text-success">
                            <b>Principe 7 : Vivre ses émotions sans nécessairement utiliser la nourriture</b>
                        </p>
                        {
                            (selections[16]
                                && ([71, 72, 73].includes(selections[16].subQuestions[14].choice_answer_id)
                                || [71, 72, 73].includes(selections[16].subQuestions[15].choice_answer_id))) ?
                                <p className="reportText has-text-success-dark">
                                    Manger peut amener du réconfort à court terme, mais ne permet 
                                    pas d’aller à la source de notre inconfort et de bien accueillir l’émotion. 
                                    Il faut plutôt se questionner sur l’émotion que l’on vit afin de 
                                    trouver un moyen de mieux répondre au besoin non comblé. 
                                </p>
                                :
                                null
                        }
                        {
                            (selections[16]
                                && selections[16].subQuestions[2].choice_answer_id === 74
                                && selections[16].subQuestions[3].choice_answer_id === 74) ?
                                <p className="reportText has-text-success-dark">
                                    Manger peut amener du réconfort à court terme, mais ne permet pas d’aller 
                                    à la source de notre inconfort et de bien accueillir l’émotion. 
                                    Assure-toi de te questionner sur l’émotion que tu vis 
                                    afin de trouver un moyen de mieux répondre au besoin non comblé.  
                                </p>
                                :
                                null
                        }
                        <br />
                        <p className="reportText has-text-success">
                            <b>Principe 8 : Honorer sa santé et ses papilles gustatives par ses aliments préférés</b>
                        </p>
                        {
                            (selections[16]
                                && [69, 70, 71].includes(selections[16].subQuestions[16].choice_answer_id)) ?
                                <p className="reportText has-text-success-dark">
                                    Aimer les aliments que l’on mange est la base pour apprécier les repas et se faire plaisir.
                                </p>
                                :
                                null
                        }
                        {
                            (selections[16]
                                && selections[16].subQuestions[16].choice_answer_id === 74) ?
                                <p className="reportText has-text-success-dark">
                                    Assure-toi d’apprécier les aliments que tu manges pour te faire plaisir.
                                </p> : null
                        }
                        <br />
                        <p className="reportText has-text-success">
                            <b>Principe 9 : Respecter son corps tel qu’il est au moment présent</b>
                        </p>
                        <p className="reportText has-text-success">
                            Ce principe souligne l’importance d’apprendre à aimer son corps et à s’accepter tel qu’on est. Il n’a pas été questionné. 
                        </p>
                        <br />
                        <p className="reportText has-text-success">
                            <b>Principe 10 : Être physiquement actif par plaisir, et non pour perdre du poids</b>
                        </p>
                        <p className="reportText has-text-success">
                        Ce principe encourage la découverte des bienfaits de bouger autre que pour brûler 
                        les calories ou essayer de changer ton apparence physique. Il n’a pas été questionné.
                        <br /><br />
                            <span style={{color:'darkred'}}>
                                Si tu souhaites explorer plus en profondeur l’alimentation intuitive,
                                mais que tu ne sais pas par où commencer, n’hésite pas à consulter une nutritionniste qui utilise cette approche.
                            </span>
                        </p>
                    </div>
                    <div style={{ padding: "10px 10px 10px 10px"}} className="has-background-info-light">
                        <h3 className="titlePourAllerPLusLoin has-text-info">Pour aller plus loin</h3>
                        <div className="content has-background-info-light">
                            <p className="textPourAllerPLusLoin">
                                <a href="https://opdq.org/trouver-une-professionnelle/" target="_blank">
                                    <span style={{marginRight: "8px"}} className="icon">
                                        <i className="fas fa-hand-point-right"></i>
                                    </span>
                                    <i>Trouve une nutritionniste près de chez toi</i>
                                </a>
                                <br />
                                <a href="https://www.karinegravel.com/approche/" target="_blank">
                                    <span style={{marginRight: "8px"}} className="icon">
                                        <i className="fas fa-hand-point-right"></i>
                                    </span>
                                    <i>Les 10 principes de l’alimentation intuitive</i>
                                </a>
                            </p>
                        </div>
                    </div>
                    <div>
                        <span style={{color:'darkred'}}>
                            <br/><br/>
                            Il est maintenant temps de passer à l'action!
                        </span>
                    </div>
                </div>
            </section>
        )
    }
    
    return (
        <div>
            <MainMenu />
            {getSections()}
        </div>
    )
}

export default Report;
