import '../styles/Credentials.css';
import preseLogo from '../../public/prese.png';
import { Link, useHistory } from 'react-router-dom';
import React, { useState, useEffect } from 'react'
import enums from '../enums';
import requests from '../clientRequests';

const { statusCodes } = enums;
const { userToken, postInscription, postConnection } = requests;

function Credentials(props) {

    const schools = ["Cégep André-Laurendeau",
                        "Cégep Beauce-Appalaches",
                        "Cégep d'Ahuntsic",
                        "Cégep de Baie-Comeau",
                        "Collège de Bois-de-Boulogne",
                        "Cégep de Chicoutimi",
                        "Cégep de Drummondville",
                        "Cégep de Granby-Haute-Yamaska",
                        "Cégep de Jonquière",
                        "Cégep de la Gaspésie et des Îles",
                        "Cégep de La Pocatière",
                        "Cégep de l'Abitibi-Témiscamingue",
                        "Cégep de Lévis-Lauzon",
                        "Cégep de Limoilou",
                        "Cégep de l'Outaouais",
                        "Collège de Maisonneuve",
                        "Cégep de Matane",
                        "Cégep de Rimouski",
                        "Cégep de Rivière-du-Loup",
                        "Collège de Rosemont",
                        "Cégep de Sainte-Foy",
                        "Cégep de Saint-Jérôme",
                        "Cégep de Saint-Laurent",
                        "Cégep de Sept-Îles",
                        "Cégep de Sherbrooke",
                        "Cégep de Sorel-Tracy",
                        "Cégep de St-Félicien",
                        "Cégep de St-Hyacinthe",
                        "Cégep de Thetford",
                        "Cégep de Trois-Rivières",
                        "Collège de Valleyfield",
                        "Cégep de Victoriaville",
                        "Cégep du Vieux Montréal",
                        "Cégep Édouard Montpetit",
                        "Cégep François-Xavier-Garneau",
                        "Cégep Gérald-Godin",
                        "Cégep John Abbott",
                        "Collège Lionel Groulx",
                        "Cégep Marie-Victorin",
                        "Collège Montmorency",
                        "Cégep régional de Lanaudière",
                        "Cégep Saint-Jean-sur-Richelieu",
                        "Champlain Regional College",
                        "Collège d'Alma",
                        "Collège Dawson",
                        "Collège Héritage",
                        "Collège Shawinigan",
                        "Vanier College",
                        "Centre matapédien d’études collégiales",
                        "Campus Notre-Dame-de-Foy",
                        "Collège André-Grasset (1973) inc.",
                        "Collège Bart (1975)",
                        "Collège Centennial",
                        "Collège Ellis",
                        "Collège International des Marcellines",
                        "Collège Jean-de-Brébeuf",
                        "Collège Laflèche",
                        "Collège LaSalle",
                        "Collège Marianopolis",
                        "Collège Mérici",
                        "Collège O'Sullivan de Montréal inc.",
                        "Collège O'Sullivan de Québec inc.",
                        "Collège TAV",
                        "Collège Universel - Campus Gatineau",
                        "Collégial international Sainte-Anne",
                        "École de musique Vincent d'Indy",
                        "École de sténographie judiciaire du Québec",
                        "École nationale de cirque",
                        "Institut Teccart",
                        "Séminaire de Sherbrooke",
                        "Air Richelieu",
                        "Conservatoire de musique de Gatineau",
                        "Conservatoire de musique de Montréal",
                        "Conservatoire de musique de Québec",
                        "Conservatoire de musique de Rimouski",
                        "Conservatoire de musique de Saguenay",
                        "Conservatoire de musique de Trois-Rivières",
                        "Conservatoire de musique de Val-d'Or",
                        "Institut de technologie agroalimentaire, campus de La Pocatière",
                        "Institut de technologie agroalimentaire, campus de Saint-Hyacinthe",
                        "Institut de tourisme et d'hôtellerie du Québec",
                        "Macdonald College – Université McGill"
                    ];
                    
    const initialHelp = props.isLogin ? "Connecte toi pour accéder à ton profil." : "Crée ton compte pour accéder au questionnaire.";
    const schoolDefault = "Ton établissement ...";

    const [selectedSchool, setSelectedSchool] = useState(schoolDefault);
    const [isSchoolOk, setIsSchoolOk] = useState(false);
    const [isEmailOk, setIsEmailOk] = useState(null);
    const [isPasswordOk, setIsPasswordOk] = useState(null);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isErrorMessage, setIsErrorMessage] = useState(false);
    const [helpMessage, setHelpMessage] = useState(initialHelp)
    const history = useHistory();

    useEffect(() => { document.body.style.backgroundColor = '#fff' }, [])

    const handleDropdown = (value) => {
        setSelectedSchool(value);

        if (value !== schoolDefault) {
            setIsSchoolOk(true);
        } else {
            setIsSchoolOk(false);
        }
    }

    const confirmCredentials = () => {
        if (!isEmailOk || !isPasswordOk || (!props.isLogin && !isSchoolOk)) {
            return false;
        }

        const credentialPromise = props.isLogin ? postConnection(email, password) : postInscription(email, password, selectedSchool);

        credentialPromise.then(jsonRes => {
            const { statusCode, token } = jsonRes;
            if (statusCode === statusCodes.api.success) {
                userToken(token);
                localStorage.setItem("token", token);
                history.push("/surveys");
            }
            else if (statusCode === statusCodes.api.userExists) {
                setHelpMessage("Le courriel entré est déja utilisé par un autre compte");
                setIsErrorMessage(true);
                setIsEmailOk(false);
            }
            else if (statusCode === statusCodes.api.badCredentials) {
                setHelpMessage("Courriel et mot de passe incorrects");
                setIsErrorMessage(true);
                setIsEmailOk(false);
                setIsPasswordOk(false);
                setEmail("");
                setPassword("");
            }
            else {
                setHelpMessage("Une erreur interne est survenue, essayer de nouveau");
                setIsErrorMessage(true);
            }
        });
    }

    const handleEmail = (value) => {
        const format = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        setEmail(value);

        if (isErrorMessage) {
            setIsErrorMessage(false);
            setHelpMessage(initialHelp);
        }

        if (format.test(value)) {
            setIsEmailOk(true);
        }
        else {
            setIsEmailOk(false);
        }
    }

    const handlePassword = (value) => {
        setPassword(value);

        if (isErrorMessage) {
            setIsErrorMessage(false);
            setHelpMessage(initialHelp);
        }

        // Leave here if we require constraints on passwords later
        if (value) {
            setIsPasswordOk(true);
        }
        else {
            setIsPasswordOk(false);
        }
    }

    const dropdownItems = schools.map((school) => {
        return (
            <option key={school}>{school}</option>
        );
    });

    const dropdown = (
        <div className="select dropdownContainer">
            <select style={{width: "100%"}} onChange={(e) => handleDropdown(e.target.value)}>
                <option>{schoolDefault}</option>
                {dropdownItems}
            </select>
        </div>
    );

    return (
        <div className="card">
            <div className="card-image imgContainer">
                <figure className="image is-3by1">
                    <img src={preseLogo} alt="Logo du PRESE" />
                </figure>
            </div>
            <div className="card-content">
                <div style={{marginBottom: props.isLogin ? "70px": "50px"}} className="contentContainer">
                    <div className="content">
                        <h2 className={"lightweight " +  (props.isLogin ? "has-text-black" : "has-text-info")}>{props.isLogin ? "Connexion" : "Inscription"}</h2>
                        <p className={"subTitle " + (isErrorMessage ? "has-text-danger" : "has-text-grey") }>{helpMessage}</p>
                    </div>
                    {
                        props.isLogin ? null : dropdown
                    }
                    <div className="field">
                        <div className="control has-icons-left">
                            <input onChange={(e) => handleEmail(e.target.value)}
                                    className={`input${(isEmailOk === false && (!props.isLogin || isErrorMessage)) ? " is-danger" : ""}`}
                                    type="email" placeholder="Courriel"
                                    value={email} />
                            <span className="icon is-small is-left">
                                <i className="fas fa-envelope"></i>
                            </span>
                        </div>
                    </div>
                    <div className="field">
                        <div className="control has-icons-left">
                            <input onChange={(e) => handlePassword(e.target.value)} className="input"
                                    type="password" placeholder="Mot de passe"
                                    className={`input${(isPasswordOk === false && (!props.isLogin || isErrorMessage)) ? " is-danger" : ""}`}
                                    value={password} />
                            <span className="icon is-small is-left">
                                <i className="fas fa-lock"></i>
                            </span>
                        </div>
                    </div>
                    <button disabled={(!isEmailOk || !isPasswordOk || (!props.isLogin && !isSchoolOk))}
                            onClick={confirmCredentials}
                            className="button is-info is-fullwidth connectBtn">{props.isLogin ? "Connexion" : "S'inscrire"}
                    </button>
                    <Link to="/signup">
                        { props.isLogin ? 
                            <p className="noAccount has-text-grey">
                                Pas de compte? Inscris-toi ici.
                            </p> : null
                        }
                    </Link>
                </div>
                
            </div>
        </div>
    );
}

export default Credentials;