
const statusCodes = {
    database: {
        success: 1,
        existingEmail: 2,
        queryError: 3,
        empty: 4,
        dataError: 5,
        badUserInit: 6,
    },
    api: {
        success: 101,
        badCredentials: 102,
        userExists: 103,
        databaseError: 104,
        failedRequest: 105,
        unauthorized: 106,
        emptySurveys: 107,
        corruptedUser: 108,
        emptyUserSelections: 109,
    },
    client: {
        noUserToken: 1001,
    }
}

const surveyStatus = {
    new: "new",
    inProgress: "in_progress",
    finished: "finished"
}

const selectionFields = {
    singleChoice: "field",
    multiChoice: "fields",
    customAnswer: "custom",
    isComplete: "isComplete",
}

export default {
    statusCodes,
    surveyStatus,
    selectionFields,
}