import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import prese from '../../public/logoPrese.png'
import clientRequests from '../clientRequests';

const { userToken } = clientRequests;

function MainMenu(props) {
    const [isWhoModalActive, setIsWhoModalActive] = useState(false);
    const [isConfModalActive, setIsConfModalActive] = useState(false);
    const [isThanksModalActive, setIsThanksModalActive] = useState(false);
    const [isHelpModalActive, setIsHelpModalActive] = useState(false);
    const [isBurgerActive, setIsBurgerActive] = useState(false);

    const history = useHistory();

    const exit = () => {
        localStorage.removeItem("token");
        userToken("");

        history.push("/login");
    }

    const navHome = () => {
        history.push("/surveys");
    }

    const toggleBurger = () => {
        setIsBurgerActive(!isBurgerActive);
    }

    return (
        <div>
            <div className={"modal " + (isWhoModalActive ? "is-active": "")}>
                <div onClick={() => setIsWhoModalActive(false)} className="modal-background" />
                <div className="modal-content">
                    <article className="message is-info">
                        <div className="message-header">
                          <p>Qui sommes-nous</p>
                          <span className="icon">
                                <i className="fas fa-user-friends"></i>
                            </span>
                        </div>
                        <div className="message-body">
                            <i>Nous sommes une équipe de recherche de l’Université de Sherbrooke qui se penche sur les saines habitudes de vie. <br />
                              Nous collaborons avec des enseignantes en éducation physique et à la santé du Cégep de Sherbrooke pour ce projet.
                            </i>
                        </div>
                    </article>
                </div>
                <button onClick={() => setIsWhoModalActive(false)} className="modal-close is-large" aria-label="close"></button>
            </div>
            <div className={"modal " + (isConfModalActive ? "is-active": "")}>
                <div onClick={() => setIsConfModalActive(false)} className="modal-background" />
                <div className="modal-content">
                    <article className="message is-info">
                        <div className="message-header">
                          <p>Confidentialité</p>
                          <span className="icon">
                                <i className="fas fa-shield-alt"></i>
                            </span>
                        </div>
                        <div className="message-body">
                          <i>La confidentialité nous tient à coeur, c'est pourquoi l'entièreté des données entrées durant les questionnaires sont automatiquement
                              <b> anonymisées</b>.
                              </i>
                        </div>
                    </article>
                </div>
                <button onClick={() => setIsConfModalActive(false)} className="modal-close is-large" aria-label="close"></button>
            </div>
            <div className={"modal " + (isThanksModalActive ? "is-active": "")}>
                <div onClick={() => setIsThanksModalActive(false)} className="modal-background" />
                <div className="modal-content">
                    <article className="message is-info">
                        <div className="message-header">
                          <p>Remerciments</p>
                          <span className="icon">
                                <i className="fas fa-hands-helping"></i>
                            </span>
                        </div>
                        <div className="message-body">
                          <i>Ce projet a été financé par le <b>Pôle régional en enseignement supérieur de l’Estrie</b>
                                 {" "}(<a href="https://www.prese.ca/accueil/" target="_blank">PRESE</a>).<br />
                                 Nous remercions également le <b>centre de calcul scientifique de l'Université de Sherbrooke</b> pour 
                                 l'hébergement et la supervision du développement de la plateforme.</i>
                        </div>
                    </article>
                </div>
                <button onClick={() => setIsThanksModalActive(false)} className="modal-close is-large" aria-label="close"></button>
            </div>
            <div className={"modal " + (isHelpModalActive ? "is-active": "")}>
                <div onClick={() => setIsHelpModalActive(false)} className="modal-background" />
                <div className="modal-content">
                    <article className="message is-info">
                        <div className="message-header">
                            <p>Aide</p>
                            <span className="icon">
                                <i className="fas fa-question"></i>
                            </span>
                        </div>
                        <div className="message-body">
                            <i>1. Tu dois terminer un questionnaire pour que tes réponses soient enregistrées. Quitter un questionnaire en cours te fera
                            perdre tes progrès.<br /><br />
                            2. Tu peux te connecter à ton compte tant que celui-ci existe dans notre base de données.
                            Il sera supprimé à la fin de ton année ou session scolaire.</i>
                        </div>
                    </article>
                </div>
                <button onClick={() => setIsHelpModalActive(false)} className="modal-close is-large" aria-label="close"></button>
            </div>
            <nav className="navbar mainNav" role="navigation" aria-label="main navigation">
                <div className="navbar-brand">
                    <figure className="image is-48x48 preseImage">
                        <img src={prese} alt="Logo du PRESE" />
                    </figure>
                    <a onClick={toggleBurger} role="button" className={"navbar-burger" + (isBurgerActive ? " is-active" : "")} 
                                                                            aria-label="menu" aria-expanded="false" data-target="mainNavbar">
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                        <span aria-hidden="true"></span>
                    </a>
                </div>
                <div id="mainNavbar" className={"navbar-menu" + (isBurgerActive ? " is-active" : "")}>
                    <div className="navbar-start">
                        <a onClick={navHome} className="navbar-item">
                            Accueil
                        </a>
                        <a onClick={() => setIsHelpModalActive(true)} className="navbar-item">
                            Aide
                        </a>
                        <div className="navbar-item has-dropdown is-hoverable">
                            <a className="navbar-link">
                                À propos
                            </a>
                            <div className="navbar-dropdown">
                                <a onClick={() => setIsConfModalActive(true)} className="navbar-item">
                                    Confidentialité
                                </a>
                                <a onClick={() => setIsWhoModalActive(true)} className="navbar-item">
                                    Qui sommes-nous?
                                </a>
                                <a onClick={() => setIsThanksModalActive(true)} className="navbar-item">
                                    Remerciements
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="navbar-end">
                        <div className="navbar-item">
                            <button onClick={exit} style={{marginRight: '20px'}} className="button is-primary is-rounded">
                                Quitter
                            </button>                            
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default MainMenu;
