import React, { useEffect, useRef, useState } from "react";
import useStickyHeader from "../hooks/useStickyHeader";
import "../styles/Question.css"

function Question(props) {
    const {
        question,
        selectedChoices,
        handleAnswer,
        handleMulti,
        handleSingle,
        handleCustom,
        setIsComplete,
        isDisabled,
        titleClass,
        adminNumUsers,
        adminAllAnswers
    } = props;

    const { tableRef, isSticky } = useStickyHeader();
    const [selected, setSelected] = useState({});
    const [activeModal, setActiveModal] = useState(null);
    const [customAnswer, setCustomAnswer] = useState("");

    const infoModals = useRef({});

    useEffect(() => {
        setSelected(selectedChoices[question.question_id] || {});
        setCustomAnswer((selectedChoices[question.question_id] && selectedChoices[question.question_id].custom) || "");
    }, [])
        
    const title = (
        <div className="questionTitle has-text-success">
            <div className={titleClass} style={{marginRight: "12px", marginBottom: "10px"}}>{ question.question_id }.</div>
            { question.question_text }
        </div>
    );

    const subQuestionClick = (e, questionId, subQuestionId) => {
        const value = parseInt(e.target.value);
        const subLength = Object.keys({ ...selected, [subQuestionId]: value }).length;      // Avoids bugs due to async state changes

        setSelected({ ...selected, [subQuestionId]: value });
        handleAnswer(value, questionId, subQuestionId);

        const mandatorySubs = question.sub_questions.filter(sub => sub.has_selection_box);

        if (subLength === mandatorySubs.length) {
            setIsComplete(questionId, true);
        }
    }

    const singleAnswersClicked = (e, questionId) => {
        const value = parseInt(e.target.value);

        if (selected.field === undefined) {
            setSelected({field: null});
        }
        setSelected({ field: value });
        handleSingle(value, questionId);

        setIsComplete(questionId, true);
    }

    const multiAnswersClicked = (value, questionId) => {
        let found = false;
        let newArray = [];
        const numValue = parseInt(value);

        if (selected.fields === undefined) {
            setSelected({fields: [numValue]});
            handleMulti(numValue, questionId, true);
            setIsComplete(questionId, true);
        }
        else {
            newArray = selected.fields.filter(element => {
                if (element === numValue) {
                    found = true;
                    return false;
                }
                return true;
            });

            if (found) {
                setSelected({ fields: newArray });
                handleMulti(numValue, questionId, false);
                if (!newArray.length) {
                    setIsComplete(questionId, false);
                }
            }
            else {
                setSelected({ fields: [...selected.fields, numValue]});
                handleMulti(numValue, questionId, true);
                setIsComplete(questionId, true);
            }
        }
    }

    const getTitles = () => {
        const choiceAnswers = question.choice_answers;
        choiceAnswers.sort((a, b) => a.answer_order - b.answer_order);
        const nbspRegex = /(&nbsp)/g;

        return choiceAnswers.map(answer => {
            return (
                <th key={question.question_id + "_" + answer.choice_answer_id} style={{verticalAlign: "middle", borderLeft: "solid 1px #dbdbdb"}}>
                    <div className="choiceAnswersTitles has-text-info">
                        { answer.content.replace(nbspRegex, "\u00A0") }
                    </div>
                </th>
            );
        });
    };

    const displayFieldStats = (answerId) => {
        return adminAllAnswers.fields.reduce((acc, field) => {
            if (field === answerId) {
                return acc + 1;
            }
            return acc;
        }, 0);
    };

    const displaySubStats = (subId, answerId) => {
        return adminAllAnswers[subId].reduce((acc, answer) => {
            if (answer === answerId) {
                return acc + 1;
            }
            return acc;
        }, 0);
    };

    const populateAnswers = (subQuestionId) => {
        return question.choice_answers.map(answer => {
            return (
                <td key={answer.choice_answer_id} style={{verticalAlign: "middle"}}>
                    <div className="choiceAnswersButton">
                        {
                            !(Object.keys(adminAllAnswers).length) ?
                            <input checked={selected[subQuestionId] !== undefined && selected[subQuestionId] === answer.choice_answer_id}
                            onChange={(e) => subQuestionClick(e, question.question_id, subQuestionId)}
                            type="radio" value={answer.choice_answer_id} name={question.question_id + "_" + subQuestionId} />
                            : 
                            <div className="content">
                                <p className="has-text-primary-dark">{displaySubStats(subQuestionId, answer.choice_answer_id)}/{adminNumUsers}</p>
                            </div>
                        }
                    </div>
                </td>
            );
        });
    }

    const getSubQuestions = () => {
        const subQuestions = question.sub_questions;
        subQuestions.sort((a, b) => a.sub_question_number - b.sub_question_number);
        return subQuestions.map(sub => {
            const extraRegex = /({.+}){1}/gm;
            const curlyRegex = /({|})+/gm
            let finalText = sub.content;
            const occurence = sub.content.match(extraRegex);

            if (occurence) {
                const text = occurence[0];
                const noCurly = text.replace(curlyRegex, "");
                finalText = sub.content.replace(text, '<span class="innerSpan">' + noCurly + '</span>');

                if (sub.extra_content) {
                    infoModals.current[sub.sub_question_number] = { title: noCurly, text: sub.extra_content};
                }
            }
            
            return (
                <tr key={question.question_id + "_" + sub.sub_question_number}>
                    <th colSpan={sub.has_selection_box ? 1 : (question.choice_answers.length + 1)}>
                        <div className={ sub.has_selection_box ? "subContainer" : "noBoxInput"}>
                            <div className="subQuestions has-text-grey-dark" dangerouslySetInnerHTML={{__html: finalText}} />
                            { occurence && sub.extra_content ?
                                <a onClick={() => setActiveModal(parseInt(sub.sub_question_number))}
                                    style={{marginLeft: "12px"}}
                                    className="icon has-text-info">
                                    <i style={{width: "10px", height: "12px"}} className="fas fa-info"></i>
                                </a> : null }
                                { sub.has_selection_box ? null :
                                    <div style={{marginLeft: "20px", flexGrow: 1}}>
                                        <input onChange={(e) => {
                                                setCustomAnswer(e.target.value);
                                                handleCustom(e.target.value, question.question_id, true);
                                            }}
                                            className="input" type="text" placeholder="Spécifie ici..." 
                                            value={customAnswer || ""} />
                                    </div>
                                }
                        </div>
                    </th>
                    { sub.has_selection_box ? populateAnswers(sub.sub_question_id) : null }
                </tr>
            );
        });
    }

    const renderHeader = (addPad) => (
        <thead>
            <tr id={"firstRow" + question.question_id}>
                { addPad ? <th id={"firstCol" + question.question_id} /> : null }
                {getTitles()}
            </tr>
        </thead>
    );

    const getAnswersOnly = () => {
        return question.choice_answers.map(choice => {
            const extraRegex = /({.+}){1}/gm;
            const curlyRegex = /({|})+/gm
            const noCurly = choice.content.replace(curlyRegex, "");
            const isExtra = extraRegex.test(choice.content);

            if (isExtra && choice.extra_content) {
                infoModals.current[choice.choice_answer_id] = { title: noCurly, text: choice.extra_content};
            }

            if (question.is_multi_select) {
                return (
                    <div className="multiContainer" key={"answer_" + question.question_id + choice.choice_answer_id}>
                        {
                            (!adminAllAnswers.fields || !adminAllAnswers.fields.length) ?
                            <label className="has-text-grey-dark">
                                <input disabled={isDisabled} style={{marginRight: "10px"}} name={choice.choice_answer_id} type="checkbox"
                                    checked={!isDisabled && ((selected.fields !== undefined && selected.fields.includes(choice.choice_answer_id))
                                                || (!choice.has_selection_box && customAnswer.length))}
                                    onChange={(e) => {
                                        if (choice.has_selection_box) {
                                            multiAnswersClicked(e.target.value, question.question_id)}
                                        }
                                    }
                                    value={choice.choice_answer_id} />
                                {noCurly}
                            </label>
                                :
                                <div className="content adminField">
                                    <p style={{marginRight: "12px"}}
                                    className="has-text-primary-dark">{displayFieldStats(choice.choice_answer_id)}/{adminNumUsers}
                                    </p>
                                    {noCurly}
                                </div>
                        }
                        {choice.has_selection_box ? null :
                            <div style={{marginLeft: "12px", width: "30%"}}>
                                <input disabled={isDisabled} onChange={(e) => {
                                    setCustomAnswer(e.target.value);
                                    handleCustom(e.target.value, question.question_id, false, choice.choice_answer_id);

                                    if (e.target.value.length) {
                                        setIsComplete(question.question_id, true);
                                    }
                                    else if (!selected.fields || !selected.fields.length) {
                                        setIsComplete(question.question_id, false);
                                    }
                                }} className="input is-small" type="text" placeholder="Spécifie ici..." value={isDisabled ? "" : (customAnswer || "")} />
                            </div>
                        }
                        {isExtra ? 
                            <a style={{marginLeft: "6px"}}
                                onClick={() => setActiveModal(parseInt(choice.choice_answer_id))}
                                className="icon has-text-info">
                                <i style={{width: "10px", height: "12px", marginLeft: "8px"}} className="fas fa-info"></i>
                            </a> : null}
                    </div>
                );
            } else {
                return (
                    <div key={"answer_" + question.question_id + choice.choice_answer_id}>
                        { 
                            (!adminAllAnswers.fields || !adminAllAnswers.fields.length) ?
                            <label className="has-text-grey-dark">
                                <input style={{marginRight: "10px"}} name={choice.choice_answer_id} type="radio"
                                    checked={selected.field !== undefined && selected.field === choice.choice_answer_id}
                                    onChange={(e) => singleAnswersClicked(e, question.question_id)} 
                                    value={choice.choice_answer_id} />
                                {noCurly}
                            </label>
                            :
                            <div className="content adminField">
                                    <p style={{marginRight: "12px"}}
                                    className="has-text-primary-dark">{displayFieldStats(choice.choice_answer_id)}/{adminNumUsers}
                                    </p>
                                    {noCurly}
                            </div>
                        }
                        {isExtra ?
                            <a style={{marginLeft: "12px"}} onClick={() => setActiveModal(parseInt(choice.choice_answer_id))} className="icon has-text-info">
                                <i style={{width: "10px", height: "12px", marginLeft: "8px"}} className="fas fa-info"></i>
                            </a> : null}
                    </div>
                );
            }
        });
    }

    const getModals = () => {
        return Object.keys(infoModals.current).map(modalId => {
            const modalText = infoModals.current[modalId].text;
            const modalTitle = infoModals.current[modalId].title;
            const titleizedTitle = modalTitle.charAt(0).toUpperCase() + modalTitle.slice(1);

            return (
                    <div key={"infoModal_" + question.question_id + "_" + modalId}
                        className={"modal " + (activeModal === parseInt(modalId) ? "is-active": "")}>
                        <div onClick={() => setActiveModal(null)} className="modal-background" />
                        <div className="modal-content">
                            <article className="message is-info">
                                <div className="message-header">
                                  <p style={{marginBottom: "0"}}>{titleizedTitle}</p>
                                  <span className="icon">
                                        <i className="fas fa-info"></i>
                                    </span>
                                </div>
                                <div className="message-body" dangerouslySetInnerHTML={{__html: modalText}} />
                            </article>
                        </div>
                        <button onClick={() => setActiveModal(null)} className="modal-close is-large" aria-label="close"></button>
                    </div>
            );
        });
    }

    if (question.sub_questions.length) {
        const col = document.getElementById("firstCol" + question.question_id);
        const row = document.getElementById("firstRow" + question.question_id);
        // Subquestions with radio buttons for choice answers
        return (
            <div>
                {getModals()}
                <div>
                {title}
                <table ref={tableRef} style={{marginBottom: "30px", marginTop: "20px"}} className="table is-bordered">
                    {renderHeader(true)}
                    <tbody>
                        {getSubQuestions()}
                    </tbody>
                </table>
                {isSticky && (
                    <table
                      className="sticky"
                      style={{
                        position: "fixed",
                        top: 0,
                        backgroundColor: "white",
                        marginLeft: col.clientWidth,
                        width: (row.clientWidth - col.clientWidth),
                      }}
                    >
                      {renderHeader(false)}
                    </table>
                )}
            </div>
            </div>
        );
    }
    return (
        <div>
            {getModals()}
            {title}
            <form className="choiceAnswers">
                {getAnswersOnly()}
                <br />
            </form>
        </div>
    );
}

export default Question;