import { request } from "express";
import enums from "./enums";

const { statusCodes } = enums;

const userToken = (function() {
    let token = undefined;

    return function (newToken) {
        if (newToken !== undefined) {
            token = newToken;
        }
        else if (token === undefined) {
            // check local storage
            const storedToken = localStorage.getItem("token");

            if (storedToken) {
                return storedToken;
            }
        }
        
        return token;
    }
})();

function postInscription(email, password, school) {
    const urlStr = "/api/inscription";
        
    const header = new Headers();
    header.append("Content-Type", "application/x-www-form-urlencoded");
    
    const requestObject = {
        method: "POST",
        headers: header,
        mode: "cors",
        cache: "default",
        body: new URLSearchParams({
            "email": email,
            "password": password,
            "school": school
        }),
    };

    return fetch(urlStr, requestObject).then(response => {
        return response.json();
    }).catch(err => {
        console.log("Error in api request for inscription: ", err);
        return Promise.resolve({ statusCode: statusCodes.api.failedRequest });
    });
}

function postConnection(email, password) {
    const urlStr = "/api/connexion";
        
    const header = new Headers();
    header.append("Content-Type", "application/x-www-form-urlencoded");

    const requestObject = {
        method: "POST",
        headers: header,
        mode: "cors",
        cache: "default",
        body: new URLSearchParams({
            "email": email,
            "password": password
        }),
    };

    return fetch(urlStr, requestObject).then(response => {
        return response.json();
    }).catch(err => {
        console.log("Error in api request for connection: ", err);
        return Promise.resolve({ statusCode: statusCodes.api.failedRequest });
    });
}

function getSurveys() {
    const urlStr = "/api/surveys";
    const token = userToken();

    if (!token) {
        return Promise.resolve({ statusCode: statusCodes.client.noUserToken });
    }
        
    const header = new Headers();
    header.append("Content-Type", "application/x-www-form-urlencoded");
    header.append("Authorization", `Bearer ${token}`);

    const requestObject = {
        method: "GET",
        headers: header,
        mode: "cors",
        cache: "default"
    };

    return fetch(urlStr, requestObject)
    .then(response => response.json())
    .catch(err => {
        console.log("Error in api request for surveys: ", err);
    });
}

function getSurveyQuestions(surveyId) {
    const urlStr = `/api/surveyQuestions?surveyId=${surveyId}`;
    const token = userToken();

    if (!token) {
        return Promise.resolve({ statusCode: statusCodes.client.noUserToken });
    }
        
    const header = new Headers();
    header.append("Authorization", `Bearer ${token}`);

    const requestObject = {
        method: "GET",
        headers: header,
        mode: "cors",
        cache: "default",
    };

    return fetch(urlStr, requestObject)
    .then(response => response.json())
    .catch(err => {
        console.log("Error in api request for surveys: ", err);
    });
}

function startSurvey(surveyId) {
    const urlStr = "/api/startSurvey";
    const token = userToken();

    if (!token) {
        return Promise.resolve({ statusCode: statusCodes.client.noUserToken });
    }
        
    const header = new Headers();
    header.append("Content-Type", "application/x-www-form-urlencoded");
    header.append("Authorization", `Bearer ${token}`);

    const requestObject = {
        method: "POST",
        headers: header,
        mode: "cors",
        cache: "default",
        body: new URLSearchParams({
            "surveyId": surveyId
        }),
    };

    return fetch(urlStr, requestObject)
    .then(response => response.json())
    .catch(err => {
        console.log("Error in api request for survey start", err);
    });
}

function finishSurvey(surveyId, selections) {
    const urlStr = "/api/finishSurvey";
    const token = userToken();

    if (!token) {
        return Promise.resolve({ statusCode: statusCodes.client.noUserToken });
    }
        
    const header = new Headers();
    header.append("Content-Type", "application/x-www-form-urlencoded");
    header.append("Authorization", `Bearer ${token}`);

    const requestObject = {
        method: "POST",
        headers: header,
        mode: "cors",
        cache: "default",
        body: new URLSearchParams({
            "surveyId": surveyId,
            "selections": JSON.stringify(selections),
        }),
    };

    return fetch(urlStr, requestObject)
    .then(response => response.json())
    .catch(err => {
        console.log("Error in api request for survey finish", err);
    });
}

function getAnswerSelections(surveyId) {
    const urlStr = `/api/userSelections?surveyId=${surveyId}`;
    const token = userToken();

    if (!token) {
        return Promise.resolve({ statusCode: statusCodes.client.noUserToken });
    }
        
    const header = new Headers();
    header.append("Authorization", `Bearer ${token}`);

    const requestObject = {
        method: "GET",
        headers: header,
        mode: "cors",
        cache: "default",
    };

    return fetch(urlStr, requestObject)
    .then(response => response.json())
    .catch(err => {
        console.log("Error in api request for GET on user selections", err);
    });
}

function getAllUserAnswers(surveyId) {
    const urlStr = `/api/admin/allAnswers?surveyId=${surveyId}`;
    const token = userToken();

    if (!token) {
        return Promise.resolve({ statusCode: statusCodes.client.noUserToken });
    }
        
    const header = new Headers();
    header.append("Authorization", `Bearer ${token}`);

    const requestObject = {
        method: "GET",
        headers: header,
        mode: "cors",
        cache: "default",
    };

    return fetch(urlStr, requestObject)
    .then(response => response.json())
    .catch(err => {
        console.log("Error in api request for GET on all answers", err);
    });
}

export default {
    userToken,
    postInscription,
    postConnection,
    getSurveys,
    getSurveyQuestions,
    startSurvey,
    finishSurvey,
    getAnswerSelections,
    getAllUserAnswers,
};
