import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from './Login';
import Signup from './Signup';
import Surveys from './Surveys';
import Questionnaire from './Questionnaire';
import Report from './Report';
import './styles/App.css'

const App = () => (
  <Switch>
    <Route exact path="/" component={Login} />
    <Route path="/login" component={Login} />
    <Route path="/signup" component={Signup} />
    <Route path="/surveys" component={Surveys} />
    <Route path="/questionnaire/:surveyId" component={Questionnaire} />
    <Route path="/report/:surveyId" component={Report} />
  </Switch>
);

export default App;
