import React, { useState, useEffect, useRef } from 'react';
import Question from './components/Question';
import { useHistory, useParams } from 'react-router-dom';
import requests from './clientRequests';
import './styles/Questionnaire.css';
import MainMenu from './components/MainMenu';
import enums from './enums';

const { getSurveyQuestions, startSurvey, finishSurvey, getAllUserAnswers } = requests;
const { statusCodes } = enums;

function Questionnaire(props) {
    const { surveyId } = useParams();
    const [questionnaire, setQuestionnaire] = useState([]);
    const [pageNumber, setPageNumber] = useState(0);
    const [isStartModalActive, setIsStartModalActive] = useState(false);
    const [wasStartModalSeen, setWasStartModalSeen] = useState(false);
    const [startModalPage, setStartModalPage] = useState(0);
    const [nextQuestionId, setNextQuestionId] = useState(1);
    const [numOfPages, setNumOfPages] = useState(0);
    const [isEndModalActive, setIsEndModalActive] = useState(false);
    const [isConfirmationModalActive, setIsConfirmationModalActive] = useState(false);
    const [isIncompletePageModalActive, setIsIncompletePageModalActive] = useState(false);
    const [isSaveErrorModalActive, setIsSaveErrorModalActive] = useState(false);
    const [isOptionalQuestionDisabled, setIsOptionalQuestionDisabled] = useState(false);
    const [newSectionIds, setNewSectionIds] = useState([]);
    const [allUserAnswers, setAllUserAnswers] = useState({});
    const [totalUsers, setTotalUsers] = useState(0);
    const [isAdmin, setIsAdmin] = useState(false);

    const maxEntriesPerPage = 20;
    const optionalQuestionId = 9;
    const disablingChoiceId = 108;

    const numOfQuestionsInPage = useRef(0);
    const numOfQuestionStack = useRef([]);
    const isLastPage = useRef(false);

    // Map of questionId to selected answer's id (choice_answer_id)
    const selectedChoices = useRef({});

    const modalMessages = [
        <p className="startModalText has-text-grey-dark">
            Au fil du questionnaire, certains mots apparaîtront en bleu. Clique sur l’icône
            information (i) placé près du mot pour en connaître la définition.
        </p>
        // ,
        // <p className="startModalText has-text-grey-dark">
        //     Tu peux toujours revenir en arrière à l’aide des flèches situées en haut ou en bas
        //     de l’écran pour voir et/ou modifier tes réponses aux questions précédentes.
        // </p>
    ]

    const sectionTitles = ["Pour en connaître sur toi", "Ton alimentation habituelle", "Tes habitudes alimentaires"];
    const sectionColors = ["has-text-info-dark", "has-text-primary-dark", "has-text-warning-dark"];

    const history = useHistory();

    useEffect(() => { document.body.style.backgroundColor = '#f8fbfa' }, []);
    useEffect(() => {
        return getSurveyQuestions(surveyId).then(res => {
            if (res.statusCode === statusCodes.client.noUserToken) {
                return history.replace("/login");
            }
            else if (res.statusCode === statusCodes.api.success) {
                if (!res.isAdmin) {
                    return setQuestionnaire(res.surveyQuestions);
                }
                setIsAdmin(true);
                setQuestionnaire(res.surveyQuestions);
                return getAllUserAnswers(surveyId).then(response => {
                    if (response.statusCode === statusCodes.api.success && response.allAnswers && response.allAnswers.length) {
                        setTotalUsers(response.totalUsers);

                        const answersDict = response.allAnswers.reduce((acc, obj) => {
                            if (!acc[obj.question_id]) {
                                if (obj.sub_question_id) {
                                    return { ...acc, [obj.question_id]: { [obj.sub_question_id]: [obj.choice_answer_id] }};
                                } else {
                                    return { ...acc, [obj.question_id]: { fields: [obj.choice_answer_id]}};
                                }
                            }
                            if (obj.sub_question_id) {
                                if (acc[obj.question_id][obj.sub_question_id]) {
                                    return {
                                        ...acc,
                                        [obj.question_id]: { 
                                            ...acc[obj.question_id],
                                            [obj.sub_question_id]: [...acc[obj.question_id][obj.sub_question_id], obj.choice_answer_id]
                                        }
                                    };
                                }
                                return {
                                    ...acc,
                                    [obj.question_id]: { 
                                        ...acc[obj.question_id],
                                        [obj.sub_question_id]: [obj.choice_answer_id]
                                    }
                                };
                            } else {
                                return { ...acc, [obj.question_id]: { fields: [...acc[obj.question_id].fields, obj.choice_answer_id]}};
                            }
                        }, {});

                        return setAllUserAnswers(answersDict);
                    }
                    return response.statusCode;
                });
            }
            else if (res.statusCode === statusCodes.api.unauthorized) {
                return history.replace("/login");
            }
        });
    }, []);

    const initializePages = () => {
        let numberOfEntries = 0;
        let currentEntries = 0;

        let lastQuestionSection = null;
        const sectionIdsBuffer = [];

        let pages = questionnaire.reduce((acc, question) => {
            currentEntries = 0;

            if (question.section_number !== lastQuestionSection) {
                sectionIdsBuffer.push(question.question_id);
            }

            lastQuestionSection = question.section_number;

            if (question.sub_questions.length) {
                numberOfEntries += question.sub_questions.length;
                currentEntries += question.sub_questions.length;
            }
            else if (question.choice_answers.length) {
                numberOfEntries += question.choice_answers.length;
                currentEntries += question.choice_answers.length;
            }
            else {
                numberOfEntries += 1;
                currentEntries += 1;
            }

            if (numberOfEntries > maxEntriesPerPage) {
                numberOfEntries = currentEntries;
                return acc + 1;
            }

            return acc;
        }, 0);

        if (numberOfEntries !== 0) {
            pages += 1;
        }

        setNewSectionIds(sectionIdsBuffer);
        setNumOfPages(pages);
    };


    const goBack = () => {
        setPageNumber(pageNumber - 1);
        window.scrollTo(0, 0);
        setNextQuestionId(nextQuestionId - (numOfQuestionStack.current.pop() || 0));
        return true;
    }

    const goNext = () => {
        let processedQuestions = 0;
        let disabledQuestions = 0;

        const isPageComplete = Object.keys(selectedChoices.current).reduce((acc, val) => {
            if (val < nextQuestionId || val >= nextQuestionId + numOfQuestionsInPage.current) {
                return acc;
            }

            ++processedQuestions;

            if (selectedChoices.current[val].isDisabled) {
                disabledQuestions++;
                return acc;
            }

            if (acc) {
                return selectedChoices.current[val].isComplete;
            }

            return acc;
        }, true);

        let isNumOfQuestionsOk;
        if (processedQuestions !== numOfQuestionsInPage.current) {
            if (processedQuestions === numOfQuestionsInPage.current - disabledQuestions) {
                isNumOfQuestionsOk = true;
            }
            else {
                isNumOfQuestionsOk = false;
            }
        }
        else {
            isNumOfQuestionsOk = true;
        }

        if (!isAdmin && (!isPageComplete || !isNumOfQuestionsOk)) {
            return setIsIncompletePageModalActive(true);
        }

        if (!isLastPage.current) {
            setPageNumber(pageNumber + 1);
            numOfQuestionStack.current.push(numOfQuestionsInPage.current)
            window.scrollTo(0, 0);
        }
        else if (!isAdmin) {
            return setIsConfirmationModalActive(true);
        }
        else {
            return false;
        }

        setNextQuestionId(nextQuestionId + numOfQuestionsInPage.current);
        return true;
    }

    const handleAnswerInput = (value, questionId, subQuestionId) => {
        if (!selectedChoices.current[questionId]) {
            selectedChoices.current[questionId] = {isComplete: false};
        }
        selectedChoices.current[questionId][subQuestionId] = value;
    }

    const handleMultiChoice = (value, questionId, shouldAdd) => {
        if (!selectedChoices.current[questionId]) {
            selectedChoices.current[questionId] = {isComplete: false, fields: []};
        }
        if (shouldAdd) {
            selectedChoices.current[questionId].fields = [...selectedChoices.current[questionId].fields, value];
            if (questionId === optionalQuestionId - 1 && value === disablingChoiceId) {
                if (!selectedChoices.current[optionalQuestionId]) {
                    selectedChoices.current[optionalQuestionId] = {isComplete: false, fields: []};
                }
                selectedChoices.current[optionalQuestionId].isDisabled = true;
                selectedChoices.current[optionalQuestionId].isComplete = false;

                setIsOptionalQuestionDisabled(true);
            }
        }
        else {
            selectedChoices.current[questionId].fields = selectedChoices.current[questionId].fields.filter(answer => answer !== value);
            if (questionId === optionalQuestionId - 1 && value === disablingChoiceId) {
                selectedChoices.current[optionalQuestionId].isDisabled = false;

                if (selectedChoices.current[optionalQuestionId].fields.length) {
                    selectedChoices.current[optionalQuestionId].isComplete = true;
                }
                setIsOptionalQuestionDisabled(false);
            }
        }
    }

    const handleSingleChoice = (value, questionId) => {
        if (!selectedChoices.current[questionId]) {
            selectedChoices.current[questionId] = {isComplete: false, field: null};
        }
        selectedChoices.current[questionId].field = value;
    }

    const handleCustomAnswer = (value, questionId, isSub, choiceId = null) => {
        if (!selectedChoices.current[questionId]) {
            if (isSub) {
                selectedChoices.current[questionId] = {};
            }
            else {
                selectedChoices.current[questionId] = {fields: []};
            }
        }

        if (!isSub) {
            if (!selectedChoices.current[questionId].fields.includes(choiceId)) {
                selectedChoices.current[questionId].fields.push(choiceId);
            }
        }
        selectedChoices.current[questionId].custom = value;
    }

    const setIsQuestionComplete = (questionId, isComplete) => {
        selectedChoices.current[questionId].isComplete = isComplete;
    }

    const getSectionTitle = (sectionName, sectionNumber) => {
        return (
            <h2 className={"surveySectionTitle " + sectionColors[sectionNumber - 1]}>{sectionName}</h2>
        );
    }

    const getPageQuestions = () => {
        let numberOfEntries = 0;
        let isDone = false;

        isLastPage.current = false;

        const maxId = questionnaire.reduce((acc, question) => {
            if (question.question_id > acc) {
                return question.question_id;
            }

            return acc;
        }, 0);

        const pageQuestions = questionnaire.filter(question => {
            if (isDone) {
                return false;
            }

            if (question.question_id < nextQuestionId) {
                return false;
            }
            if (question.sub_questions.length) {
                numberOfEntries += question.sub_questions.length;
            }
            else if (question.choice_answers.length) {
                numberOfEntries += question.choice_answers.length;
            }
            else {
                numberOfEntries += 1;
            }

            if (question.question_id !== nextQuestionId) {
                if (numberOfEntries > maxEntriesPerPage) {
                    numOfQuestionsInPage.current = question.question_id - nextQuestionId;
                    isDone = true;

                    return false;
                }
            }

            if (question.question_id === maxId) {
                if (question.question_id === nextQuestionId) {
                    numOfQuestionsInPage.current = 1;
                }
                else {
                    numOfQuestionsInPage.current = question.question_id - nextQuestionId;
                }
                isLastPage.current = true;
            }

            return true;
        });

        const formattedPageQuestions = pageQuestions.map(pageQuestion => {
            
            const question = <Question
                question={pageQuestion}
                selectedChoices={selectedChoices.current}
                handleAnswer={handleAnswerInput}
                handleMulti={handleMultiChoice}
                handleSingle={handleSingleChoice}
                handleCustom={handleCustomAnswer} 
                setIsComplete={setIsQuestionComplete}
                isDisabled={isOptionalQuestionDisabled && pageQuestion.question_id === optionalQuestionId}
                titleClass={sectionColors[pageQuestion.section_number - 1]} 
                adminNumUsers={totalUsers} 
                adminAllAnswers={allUserAnswers[pageQuestion.question_id] || {}} />
                
                if (newSectionIds.includes(pageQuestion.question_id)) {
                    return (
                        <div key={pageQuestion.question_id} style={{display: "flex", flexDirection: "column", marginTop: "50px"}}>
                            {getSectionTitle(sectionTitles[pageQuestion.section_number - 1], pageQuestion.section_number)}
                            {question}
                        </div>
                    );
                }
                return (
                    <div key={pageQuestion.question_id} style={{marginTop: "50px"}}>
                        {question}
                    </div>
                    
                );
        });
        return formattedPageQuestions;
    };

    const startQuestionnaire = () => {
        // startSurvey(surveyId);   // Use to save progress and allow students to return to survey
        initializePages();
        setPageNumber(pageNumber + 1);

        if (!wasStartModalSeen) {
            setIsStartModalActive(true);
        }
    };

    const finishQuestionnaire = () => {
        return finishSurvey(surveyId, selectedChoices.current).then(response => {
            if (response.statusCode === statusCodes.api.success) {
                setIsEndModalActive(true);
            }
            else {
                return setIsSaveErrorModalActive(true);
            }
        });
    }

    const getPagination = () => {
        return Array(numOfPages).fill(0).map((_, idx) => {
            return (
                <li key={"pageNumber_" + (idx + 1)}>
                    <a className={"pagination-link" + (pageNumber === (idx + 1) ? " is-current" : "")}
                        aria-label={"Page " + (idx + 1)}>
                            {(idx + 1)}
                        </a>
                </li>
            );
        });
    }

    const closeStartModal = () => {
        setIsStartModalActive(false);
        setWasStartModalSeen(true);
    }

    const getStartModalMessage = () => {
        return modalMessages[startModalPage];
    }

    const getStartModalButton = () => {
        if (startModalPage === modalMessages.length - 1) {
            return (
                <div className="modalButtonHolder" >
                    {/*<button onClick={() => setStartModalPage(startModalPage - 1)} className="button is-info is-outlined">*/}
                    {/*    <span className="icon">*/}
                    {/*        <i className="fas fa-arrow-left"></i>*/}
                    {/*    </span>*/}
                    {/*</button>*/}
                    <button onClick={closeStartModal} className="button is-primary">J'ai compris!</button>
                </div>
                
            )
        }

        return (
            <div className="modalButtonHolder">
                <button disabled={startModalPage <= 0} onClick={() => setStartModalPage(startModalPage - 1)} className="button is-info is-outlined">
                        <span className="icon">
                            <i className="fas fa-arrow-left"></i>
                        </span>
                    </button>
                <button onClick={() => setStartModalPage(startModalPage + 1)} className="button is-info is-outlined">
                    <span className="icon">
                        <i className="fas fa-arrow-right"></i>
                    </span>
                </button>
            </div>
        );
    }

    const getPage = () => {
        if (pageNumber === 0) {
            return (
                <div className="card">
                    <div style={{marginBottom: '45px'}} className="card-content">
                        <div className="content">
                            <div className="titleContainerFirst">
                                <h5 className="subtitle is-5 has-text-link">Choix et habitudes alimentaires</h5>
                            </div>
                            <p className="surveyMessage has-text-grey-darker" style={{fontStyle: 'italic'}}>
                                Ce questionnaire a été développé par une équipe de l’Université de Sherbrooke, dont des nutritionnistes, 
                                en collaboration avec deux enseignantes du Cégep de Sherbrooke. Il a pour but de t’aider à entamer une réflexion
                                sur tes choix et habitudes alimentaires, via un rapport personnalisé, et ce, afin de t’outiller à améliorer certains
                                aspects de ton alimentation pour favoriser une meilleure santé. <br /><br />
                                Ce n’est pas un test; il n’y a pas de bonne ou de mauvaise réponse. Considérant que ton alimentation peut varier, 
                                réponds au meilleur de tes connaissances selon ce qui représente le plus ton alimentation en général. <br /><br />
                                Tes réponses demeureront strictement confidentielles et aucune information permettant de t’identifier ne sera recueillie.
                                <br /><br /> Prévois environ 25 minutes pour compléter ce questionnaire.
                                <br/> <br/> Tu dois terminer le questionnaire pour que tes réponses soient enregistrées.</p>
                        </div>
                    </div>
                    <footer className="card-footer">
                        <button onClick={startQuestionnaire} className="button is-primary is-inverted card-footer-item">
                            Commencer
                            <span className="icon ml-1">
                                <i className="fas fa-arrow-right"></i>
                            </span>
                        </button>
                    </footer>
                </div>
            );
        } else {
            return (
                <div className="card">
                    <div className="card-content">
                        <div className="content">
                            <nav className="pagination is-centered" role="navigation" aria-label="pagination">
                                {/*<a onClick={goBack} className="pagination-previous has-background-link-light">*/}
                                {/*<i className="fas fa-arrow-left" />*/}
                                {/*</a>*/}
                                { pageNumber !== numOfPages ?
                                    <a onClick={goNext} 
                                        className="pagination-next has-background-link-light">
                                    <i className="fas fa-arrow-right" />
                                </a> : null }
                                <ul style={{listStyleType: 'none'}} className="pagination-list">
                                  {getPagination()}
                                </ul>
                            </nav>
                            {getPageQuestions(pageNumber)}
                        </div>
                    </div>
                    <footer className="card-footer">
                        <div>&nbsp;</div>
                        {/*<button onClick={goBack} className="button is-link is-inverted card-footer-item">*/}
                            {/*<span className="icon mr-1">*/}
                            {/*    <i className="fas fa-arrow-left"></i>*/}
                            {/*</span>*/}
                            {/*Précédent*/}
                        {/*</button>*/}
                        <button onClick={goNext}
                        className={ "button" + (isLastPage.current ? " is-primary" : " is-link") + " is-inverted card-footer-item" }>
                            { isLastPage.current ? "Terminer" : "Suivant" }
                            { !isLastPage.current ? <span className="icon ml-1">
                                <i className="fas fa-arrow-right"></i>
                            </span> : null }
                            { isLastPage.current ? <span className="icon ml-1">
                                <i className="fas fa-check"></i>
                            </span> : null }
                        </button>
                    </footer>
                </div>
            );
        }
    }

    return (
        <div>
            <div className={"modal" + (isStartModalActive ? " is-active" : "")}>
                <div onClick={closeStartModal} className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head startModalHeader">
                        <p className="modal-card-title has-text-info">Informations importantes</p>
                        <span className="icon">
                            <i className="fas fa-question"></i>
                        </span>
                    </header>
                    <section className="modal-card-body">
                        <div className="startModalBody">
                            {getStartModalMessage()}
                            {getStartModalButton()}
                        </div>
                    </section>
                </div>
            </div>
            <div className={"modal" + (isConfirmationModalActive ? " is-active" : "")}>
                <div onClick={() => setIsConfirmationModalActive(false)} className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head startModalHeader">
                        <p className="modal-card-title is-size-5 has-text-info">Confirmation</p>
                        <span className="icon has-text-primary">
                            <i className="fas fa-check"></i>
                        </span>
                    </header>
                    <section className="modal-card-body">
                        <div className="endModalBody">
                            <div style={{marginBottom: "20px"}}>
                                Es-tu certain(e) de vouloir terminer ce questionnaire ?
                                <br/>
                                Tu ne pourras pas changer tes réponses une fois qu'elles seront sauvegardées.
                            </div>
                            <div className="confirmButtons">
                                <div>
                                    <button style={{flexGrow: 1}} onClick={() => setIsConfirmationModalActive(false)}
                                    className="button is-info">Annuler</button>
                                </div>
                                <div>
                                    <button style={{flexGrow: 1}} onClick={() => {
                                        setIsConfirmationModalActive(false);
                                        finishQuestionnaire();
                                    }}
                                    className="button is-success">Terminer</button>
                                </div>
                            </div>
                            
                        </div>
                    </section>
                </div>
            </div>
            <div className={"modal" + (isEndModalActive ? " is-active" : "")}>
                <div className="modal-background"></div>
                <div className="modal-card">
                    <header className="modal-card-head startModalHeader">
                        <p className="modal-card-title is-size-5 has-text-info">Tes réponses ont été enregistrées.</p>
                        <span className="icon has-text-primary">
                            <i className="fas fa-check"></i>
                        </span>
                    </header>
                    <section className="modal-card-body">
                        <div className="endModalBody">
                            <div style={{marginBottom: "20px"}}>
                                Consulte ton rapport dans la section rapports de ta page d'accueil.
                            </div>
                            <div className="endModalButton">
                                <button onClick={() => {
                                    setIsEndModalActive(false);
                                    history.replace("/surveys");
                                }}
                                className="button is-success">Retour à l'accueil</button>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <div className={"modal " + (isIncompletePageModalActive ? "is-active": "")}>
                <div onClick={() => setIsIncompletePageModalActive(false)} className="modal-background" />
                <div className="modal-content">
                    <article className="message is-danger">
                        <div className="message-header">
                          <p>Page incomplète</p>
                          <span className="icon">
                                <i className="fas fa-list"></i>
                            </span>
                        </div>
                        <div className="message-body">
                            <i>Tu dois compléter toutes les questions de cette page afin de continuer.</i>
                        </div>
                    </article>
                </div>
                <button onClick={() => setIsIncompletePageModalActive(false)} className="modal-close is-large" aria-label="close"></button>
            </div>
            <div className={"modal " + (isSaveErrorModalActive ? "is-active": "")}>
                <div onClick={() => setIsSaveErrorModalActive(false)} className="modal-background" />
                <div className="modal-content">
                    <article className="message is-danger">
                        <div className="message-header">
                          <p>Erreur de sauvegarde</p>
                          <span className="icon">
                                <i className="fas fa-plug"></i>
                            </span>
                        </div>
                        <div className="message-body">
                            <i>Tes réponses n'ont pas pu être enregistrées, ne quitte pas cette page ou ton progrès sera perdu.
                                Vérifie ta connexion réseau et réessaies ensuite.</i>
                        </div>
                    </article>
                </div>
                <button onClick={() => setIsSaveErrorModalActive(false)} className="modal-close is-large" aria-label="close"></button>
            </div>
            <MainMenu />
            <section className="section">
                <div style={{marginTop: "80px"}} className="container">
                    { getPage() }
                </div>
            </section>
        </div>
    );
}

export default Questionnaire;
